import React, { FC, memo, useMemo } from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'

import { LegalTemplate } from '../../templates'
import { Layout } from '../../components'
import routes from '../../router/routes'

import * as contents from './contents'

const LegalContainer: FC = () => {
  const intl = useIntl()

  // memo

  const content = useMemo(() => {
    let locale = intl.locale
    switch (locale) {
      case 'zh-hk':
        locale = 'zh_hk'
        break
      case 'zh-cn':
        locale = 'zh_cn'
        break
      default:
        break
    }
    return contents[locale as keyof typeof contents] || []
  }, [intl.locale])

  // return

  return (
    <Layout>
      <LegalTemplate
        backgroundImage="/images/storybook/templates/homepage/background-web.jpg"
        title={intl.formatMessage({ id: 'legal.title' })}
        contents={content}
        link={routes.root.path}
        linkLabel={intl.formatMessage({ id: 'legal.linkLabel' })}
      />
    </Layout>
  )
}

export default memo(LegalContainer)

export default [
  {
    title: '1. 소개 ',
    //language=HTML
    content: `
      <p>
      포토부스 기기 구찌 플로라 프리쿠라(이하 "<b>프리쿠라</b>")를 이용해 주셔서 감사합니다. 프리쿠라를 사용하기 전에 이용 약관(이하 "<b>약관</b>")을 주의 깊게 읽으십시오. 프리쿠라는 사업자등록주소: 
      14, rue du Quatre Septembre, 75002 Paris, FRANCE, 파리 사업자등록번호: 394 710 552인 프랑스 주식회사, Coty 주식회사(이하 "<b>COTY</b>")에서 운영합니다. 
      </p>
      <p>
      프리쿠라를 사용하는 것은 본 약관을 수락하며 그 내용에 구속되는데 동의함을 의미합니다. 약관에 동의하지 않을 경우 프리쿠라를 사용할 수 없습니다. 
      </p>`,
  },
  {
    title: '2. GUCCI FLORA PURIKURA',
    //language=HTML
    content: `
      <p>프리쿠라는 사직을 찍어 플로라 캠페인 테마의 포토 몽타주를 만들고 온라인으로 공유하거나 휴대전화에 등록할 수 있는(이하 "경험") 기기입니다. 경험에 참여하려면 다음과 같은 단계를 따라 주십시오.</p>
      <ul>
        <li>홈페이지 접속 후 화면을 터치하여 경험을 시작합니다.</li>
        <li>사진 찍을 플로라 향수를 터치하고 배경의 꽃을 채우기 위해 세 장의 다른 사진을 찍습니다.
        </li>
        <li>네 장의 사진을 찍은 후 포토 몽타주에 스티커를 추가할 수 있습니다.</li>
        <li>경험을 종료하려면 확인을 누릅니다.</li>
        <li>클릭 후 잠시 기다리면 옵션이 나타납니다("휴대전화에 사진 저장 또는 SNS에 공유").
        </li>
      </ul>
      <p>
      "경험 시작" 버튼을 클릭하면 귀하 또는 귀하가 대리하는 조직이나 개인은 Coty Inc., 그 브랜드, 라이선스 부여자, 라이선스 사용자, 자회사, 
      계열사, 서비스 공급자, 수급인, 파트너, 구찌 플로라 캠페인 브랜드 앰배서더와 그 각각의 모든 디렉터, 직원, 양수인, 이해승계인, 대리인, 
      대행인(이하 개별 지칭 "<b>관련 당사자</b>" 및 통칭 "<b>관련 당사자들</b>")을 포함하여 coty.com에서 확인할 수 있는 이용 약관과 이 약관의 모든 조건을 
      포함한 본 합의(이하 "<b>합의</b>")에 조건 없이 동의하는 합의의 당사자가 됩니다. 
      </p>
      <p>
      귀하는 관련 당사자들이 귀하의 이미지를 활용하여 작품(이하 "<b>사용자 콘텐츠</b>")을 만들 수 있도록 허용합니다. 
      귀하가 조직 또는 개인을 대신하여 본 합의를 체결하는 것은 귀하에게 그러한 권한이 있음을 의미합니다.
      </p>
    `,
  },
  {
    title: '3. 개인정보',
    // language=HTML
    content: `
      <p>
        귀하는 관련 당사자들이 사용자 콘텐츠를 만들고 귀하에게 전송하기 위한 목적으로만 귀하의 이미지로 구성된 개인정보를 사용하며 이를 완료한 후 삭제한다는 것을 이해하고 동의합니다. 
      </p>
      <p>
        당사 개인정보 보호정책 <a href='https://privacy.coty.com/'
                                            target='_blank'>https://privacy.coty.com/</a>,
        에서 개인정보 보호 관련 법률에 따른 귀하의 권리를 확인할 수 있습니다.
      </p>
      <p>
        데이터 수집 48시간 후 개인정보는 완전히 파기됩니다. 
      </p>
    `,
  },
  {
    title: '4. 본 약관의 변경',
    // language=HTML
    content: `
      <p>
      귀하 관할지의 관련 법률이 허용하는 최대 범위에 따라 Coty는 본 약관을 언제든 예고 없이 개정 또는 업데이트할 권리를 보유하며 Coty가 여하한 
      개정 약관을 게시한 후 귀하가 계속하여 경험을 사용하는 것은 귀하가 그러한 개정 또는 업데이트 내용에 동의함을 의미합니다. 
      </p>
      <p>본 약관의 특정 부분이 유효하지 않거나 무효 또는 어떤 이유로든 시행될 수 없는 것으로 간주될 경우에도 다른 모든 이용약관의 유효성과 시행 가능성에는 영향을 미치지 않습니다.
      </p>
      <p>
      약관 또는 개정된 약관에 동의하지 않는 경우 프리쿠라를 사용할 수 없습니다. 
      </p>
    `,
  },
  {
    title: '5. 지적재산권',
    // language=HTML
    content: `
      <p>
      Coty에서는 프리쿠라, Coty 또는 그 대리인이 프리쿠라에 게시한 모든 자료 및 사용자 콘텐츠의 모든 지적재산권을 소유합니다. 
      </p>
      <p>
      프리쿠라 사용 시 귀하는 본 약관에 따라 프리쿠라를 사용할 제한된 권리 외에는 경험 또는 사용자 콘텐츠상 어떤 종류의 권리도 획득하지 않습니다.
      </p>
      <p>
      약관에서 엄격히 허용하는 경우와 프리쿠라의 의도에 따른 정상적인 사용을 제외하고 귀하는 프리쿠라 또는 그 콘텐츠의 어떤 부분도 복사, 복제, 
      재컴파일, 역컴파일, 분해, 배포, 출판, 게시, 실행, 수정, 2차 창작물 제작을 위해 업로드 및 전송할 수 없으며 어떤 경우에도 역엔지니어링하거나 
      기타 방법으로 악용할 수 없습니다. 
      </p>
      <p>
      귀하는 사용자 콘텐츠를 직접 또는 간접적인 상업 목적으로 사용하지 않을 것이며 Coty 또는 모든 관련 당사자들의 평판을 떨어뜨리지 않을 것에 동의합니다. 
      </p>
    `,
  },
  {
    title: '6. 보증 및 책임 제한',
    // language=HTML
    content: `
      <p>
      프리쿠라는 Coty가 '있는 그대로', 그리고 '이용 가능한 대로'의 원칙에 따라 제공합니다. Coty는 프리쿠라 사용 또는 사용자 콘텐츠에 대한 어떤 
      종류의 명시적 또는 묵시적 진술 또는 보증도 하지 않습니다. 귀하는 프리쿠라 사용에 따른 위험이 전적으로 귀하에게 있음에 명시적으로 동의합니다. 
      </p>
      <p>
      관련 법률이 허용하는 최대 범위에 따라 Coty는 상품성 또는 특정 목적 적합성에 대한 묵시적 보증을 포함한 모든 명시적 또는 묵시적 보증을 부인합니다. 
      </p>
      <p>
      Coty는 프리쿠라 사용으로 인해 발생한 직접적, 간접적, 부수적, 징벌적 및 결과적 손해를 포함한 모든 종류의 손해에 대해 책임을 지지 않습니다.
      </p>
    `,
  },
  {
    title: '7. 면책',
    // language=HTML
    content: `
      <p>
      귀하는 관련 당사자들로 인해 발생한 모든 벌금, 처벌, 책임, 손실 및 기타 모든 종류의 손해 등(변호사 비용 및 감정인 비용 포함)으로부터 Coty 
      및 관련 당사자들을 면책하고 보호하며 다음으로 인해 발생한 모든 클레임으로부터 관련 당사자들을 방어합니다. 
      </p>
      <ol>
        <li>귀하의 프리쿠라 사용 </li>
        <li>귀하의 본 약관 위반</li>
        <li>귀하가 범한 사기 또는 귀하의 고의적인 불법행위 또는 중대한 과실</li>
      </ol>
      <p>
      해당 관련 당사자는 본 면책이 적용될 수 있는 모든 클레임에 대한 방어를 통제하며 귀하는 어떠한 경우에도 해당 관련 당사자의 사전 서면 승인 없이 클레임을 해결해서는 안 됩니다.
      </p>
      <p>
      이 조항은 본 약관 위반 시 관련 당사자들이 청구할 수 있는 보상 및 손해에 대한 권리를 침해하지 않습니다.
      </p>
    `,
  },
  {
    title: '8. 완전한 합의 및 준거법',
    // language=HTML
    content: `
      <p>
        <a href='https://privacy.coty.com/en_gb/'target='_blank'>https://privacy.coty.com/en_gb/</a>
        의 Coty 개인정보 보호정책 및 프리쿠라 사용과 관련하여 귀하가 동의한 Coty의 모든 추가 약관을 포함한 본 약관은 귀하와 Coty 간의 완전한 합의로 간주됩니다.
      </p>
      <p>
      법률이 허용하는 최대 범위에 따라, 프리쿠라 사용으로 인해 귀하와 Coty 간에 발생할 수 있는 모든 유형의 분쟁 해결은 프랑스 법을 준거법으로 적용합니다. 법률이 허용하는 
      최대 범위에 따라, 귀하는 경험의 사용으로 발생하였거나 그와 관련된 모든 소송이 파리 법원에서 이루어진다는 데에 명시적으로 동의하게 됩니다.
      </p>
    `,
  },
  {
    title: '9. 문의',
    // language=HTML
    content: `
      <p>
        본 약관에 대한 질문 또는 의견은 웹사이트 <a href='https://coty.cotyconsumeraffairs.com/' target='_blank'>https://coty.cotyconsumeraffairs.com/</a>를 통해 보내주십시오.
      </p>
    `,
  },
]

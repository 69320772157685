export default [
  {
    title: '1. ВВЕДЕНИЕ',
    //language=HTML
    content: `
      <p>
        Благодарим вас за использование фотокабины «Гуччи Флора Пурикура» («<b>Пурикура</b>»).
        Обязательно внимательно ознакомьтесь с настоящими положениями и условиями («<b>Условия</b>») bперед использованием «Пурикура», 
        под управлением «Коти САС» (Coty SAS), французского акционерного общества упрощенного типа, головной офис которого находится по адресу: 14, rue du
        Quatre Septembre, 75002 Paris, FRANCE, зарегистрированного в реестре торговых компаний и акционерных обществ Парижа под номером 394 710 552 
        («<b>КОТИ</b>»).
      </p>
      <p>
        Используя «Пурикура», вы подтверждаете, что принимаете настоящие Условия и соглашаетесь соблюдать их. Если вы не согласны с Условиями, просим вас 
        воздержаться от использования «Пурикура». 
      </p>`,
  },
  {
    title: '2. ГУЧЧИ ФЛОРА ПУРИКУРА',
    //language=HTML
    content: `
      <p> «Пурикура» - это устройство, которое позволяет вам делать снимки и создавать фотомонтаж на тему «Флора» и делиться ими в Интернете или сохранять 
        в своем телефоне («Эксперимент»). Чтобы принять участие в «Эксперименте», вы должны выполнить следующие шаги:</p>
      <ul>
        <li>При переходе на главную страницу коснитесь экрана, чтобы начать «Эксперимент»;</li>
        <li>Коснитесь аромата «Флора», чтобы сделать снимок, затем вам нужно будет сделать еще три снимка, чтобы заполнить цветами задний фон;
        </li>
        <li>Как только четыре фотографии будут сделаны, вы сможете добавлять стикеры в свой фотомонтаж;</li>
        <li>Подтвердите, чтобы закончить «Эксперимент»;</li>
        <li>Нажмите и удерживайте, чтобы увидеть, какие параметры появятся («Сохранить изображение на телефоне или поделиться им в социальных сетях»).
        </li>
      </ul>
      <p>
      Нажимая кнопку «Начать Эксперимент», вы или организация или физическое лицо, которого вы представляете, безоговорочно 
        соглашаетесь быть связанными обязательствами по настоящему соглашению и становитесь его стороной(«<b>Соглашение</b>»),
        включая все приведенные здесь условия в дополнение к Условиям использования, доступным на сайте coty.com, которые включены 
        в настоящий документ, совместно с «Коти Инк.» (Coty Inc.), ее брендами, лицензиарами, лицензиатами, дочерними компаниями, 
        филиалами, поставщиками услуг, подрядчиками, партнерами, представителями брендов кампании «Гуччи Флора» и каждым из их 
        соответствующих директоров, сотрудников, правопреемников, преемников в правах на имущество, агентов и представителей
        (по отельности именуемые «<b>Связанная сторона</b>», а совместно «<b>Связанные стороны</b>»).
      </p>
      <p>
        Вы разрешаете Связанным сторонам использовать ваше изображение для создания фотошаблона («<b>Пользовательский контент</b>»). 
        Если вы заключаете настоящее Соглашение от имени организации или физического лица, вы заявляете, что у вас есть на это полномочия.
      </p>
    `,
  },
  {
    title: '3. ПЕРСОНАЛЬНЫЕ ДАННЫЕ',
    // language=HTML
    content: `
      <p>
        Вы отдаете себе отчёт и даете свое согласие на то, что ваши персональные данные, состоящие из вашего изображения, будут использоваться 
        Связанными сторонами только для создания и отправки вам Пользовательского контента и будут удалены после его завершения. 
      </p>
      <p>
        Наша Политика конфиденциальности, размещенная по адресу <a href='https://privacy.coty.com/'
                                            target='_blank'>https://privacy.coty.com/</a>,
        разъясняет ваши права в соответствии с применимыми законами о защите данных.
      </p>
      <p>
      Вы понимаете, что ваши персональные данные будут полностью уничтожены через сорок восемь (48) часов с даты сбора. 
      </p>
    `,
  },
  {
    title: '4. Change to these terms',
    // language=HTML
    content: `
      <p>
      В полной мере, разрешенной применимым законодательством в вашей юрисдикции, «Коти» оставляет за собой право 
      изменять или обновлять настоящие Условия в любое время без предварительного уведомления, и дальнейшее использование 
      вами «Эксперимента» после того, как «Коти» опубликует какие-либо пересмотренные Условия, означает, что вы соглашаетесь 
      с такими изменениями или обновления. 
      </p>
      <p>Если какое-либо из этих Условий будет признано недействительным, неприменимым или по какой-либо причине не имеющим 
      исковой силы, такое положение или условие будет рассматривается отдельно и не будет влиять на действительность и исковую 
      силу любых оставшихся положений и условий.
      </p>
      <p>
      Если в какой-либо момент времени вы не принимаете Условия или пересмотренные Условия, вы не можете использовать «Пурикура». 
      </p>
    `,
  },
  {
    title: '5. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ',
    // language=HTML
    content: `
      <p>
      «Коти» владеет всеми правами интеллектуальной собственности на «Пурикура», на материалы, опубликованные в «Пурикура» компанией 
      «Коти» или от ее имени, а также на любой Пользовательский контент. 
      </p>
      <p>
      Используя «Пурикура», вы не приобретаете никаких прав на «Эксперимент» и/или Пользовательский контент, кроме ограниченного права 
      на использование «Пурикура» в соответствии с настоящими Условиями.
      </p>
      <p>
      За исключением случаев, когда это явно разрешено Условиями и предполагаемым и нормальным использованием «Пурикура», вы не можете 
      копировать, воспроизводить, перекомпилировать, декомпилировать, дизассемблировать, распространять, публиковать, отображать, выполнять, 
      изменять, загружать для создания производных работ, передавать, в любом случае осуществлять инженерный анализ и/или любым другим способом 
      использовать любую часть «Пурикура» и/или его Контент. 
      </p>
      <p>
      Вы заявляете и гарантируете, что не будете прямо или косвенно использовать Пользовательский контент в коммерческих целях или причинять ущерб 
      репутации компании «Коти» или любым Связанным сторонам. 
      </p>
    `,
  },
  {
    title: '6. ГАРАНТИИ И ОГРАНИЧЕНИЯ ОТВЕТСТВЕННОСТИ',
    // language=HTML
    content: `
      <p>
      «Пурикура» предоставляется компаний «Коти» на основе принципа «как есть» и «при наличии». «Коти» не делает 
      никаких заявлений и не дает никаких гарантий, явных или подразумеваемых, в отношении использования «Пурикура» 
      или Пользовательского контента. Вы прямо соглашаетесь с тем, что используете «Пурикура» под свою ответственность. 
      </p>
      <p>
      В полной мере, допустимой действующим законодательством, «Коти» отказывается от всех гарантий, явных или подразумеваемых, 
      включая, помимо прочего, подразумеваемые гарантии товарного состояния и пригодности для определенной цели. 
      </p>
      <p>
      «Коти» не несет ответственности за любой ущерб, возникший в результате использования «Пурикура», включая, помимо прочего, 
      прямые, косвенные, случайные, штрафные и последующие убытки.
      </p>
    `,
  },
  {
    title: '7. ВОЗМЕЩЕНИЕ УБЫТКОВ',
    // language=HTML
    content: `
      <p>
      Вы освобождаете «Коти» и Связанные стороны от любых штрафов, пеней, обязательств, потерь и и освобождаете их от 
      ответственности за убытки любого рода (включая гонорары адвокатов и экспертов), понесенные Связанными сторонами, 
      и исключаете любые претензии к Связанным сторонам, возникающие в связи с: 
      </p>
      <ol>
        <li>использованием вами «Пурикура»,</li>
        <li>нарушением вами настоящих Условий;</li>
        <li>совершенным вами мошенничеством, умышленным неправомерным поведением или грубой небрежностью</li>
      </ol>
      <p>
      Соответствующая Связанная сторона будет контролировать любой иск, к которому может применяться данное возмещение, 
      и в любом случае урегулирование каких-либо претензий без предварительного письменного согласия такой Связанной стороны невозможно.
      </p>
      <p>
      Настоящее положение должно рассматриваться без ущерба для права на компенсацию и любой ущерб, который может быть предъявлен Связанными 
      сторонами в случае нарушения настоящих Условий.
      </p>
    `,
  },
  {
    title: '8. ИСЧЕРПЫВАЮЩИЙ ХАРАКТЕР СОГЛАШЕНИЯ И ПРИМЕНИМОЕ ЗАКОНОДАТЕЛЬСТВО',
    // language=HTML
    content: `
      <p>
      Настоящие Условия, включая Политику конфиденциальности «Коти», расположенную по адресу <a href='https://privacy.coty.com/en_gb/'
                                                                     target='_blank'>https://privacy.coty.com/en_gb/</a>
      и любые дополнительные условия, которые вы можете заключить с «Коти» в связи с использованием «Пурикура», составляют полное соглашение 
      между вами и компанией «Коти».
      </p>
      <p>
      В максимальной степени, разрешенной законом, спор любого рода, который может возникнуть между вами и «Коти» в связи с использованием 
      «Пурикура», регулируется законодательством Франции. В соответствии с законом, вы соглашаетесь с тем, что любое судебное разбирательство, 
      возникающее в связи с использованием вами «Эксперимента» или связанное с ним, должно быть возбуждено в судах Парижа.
      </p>
    `,
  },
  {
    title: '9. НАШИ КОНТАКТНЫЕ ДАННЫЕ',
    // language=HTML
    content: `
      <p>
        Если у вас есть какие-либо вопросы или комментарии относительно настоящих Условий, вы можете связаться с нами через указанный ниже сайт: 
        <a href='https://coty.cotyconsumeraffairs.com/' target='_blank'>https://coty.cotyconsumeraffairs.com/</a>
      </p>
    `,
  },
]

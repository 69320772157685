export default [
  {
    title: '1. Introduction',
    //language=HTML
    content: `
      <p>
      Obrigado por usar o GUCCI Flora Purikura, dispositivo Photobooth (o "<b>Photobooth</b>"). Leia 
      atentamente estes termos e condições ("<b>Terms</b>") antes de utilizar o  Photobooth que é operado 
      pela Coty SAS, um société francês par action simplifiée com a sua sede em 14, rue du Quatre 
      Septembre, 75002 Paris, FRANÇA, registrado com comércio e empresas de Paris sob o número 394 
      710 552 ("<b>COTY</b>").

      </p>
      <p>
      Ao utilizar o  Photoboothconfirma que aceita estes Terms  e que concorda em ser limitado por eles. Se 
      não concordar com estes TERMOS, não utilize o  Photobooth.
      </p>`,
  },
  {
    title: '2. GUCCI FLORA PURIKURA PHOTOBOOTH',
    //language=HTML
    content: `
      <p>O Photobooth  é um dispositivo que lhe permite tirar uma fotografia e criar uma montagem fotográfica 
      sobre o tema da campanha Flora e  partilhá-la online ou registada no seu telemóvel  (a "Experiência"). Para participar na Experiência,seguirá os seguintes passos: </p>
      <ul>
        <li>Ao aterrar na página inicial, toque no ecrã para iniciar a Experiência;</li>
        <li>Touch a fragrância flora para tirar afoto, você terá então que tirar três outras fotos para preencher as flores em segundoplano;
        </li>
        <li>Assim que as suas quatro  fotografias forem tiradas, poderá adicionar  autocolantes à sua montagem fotográfica;</li>
        <li>Validate  para terminar a experiência; and print your picture</li>
        <li>Clamba e segura para ver as suas opções apareceremno register telemóvel ou, share on social media…)
        </li>
      </ul>
      <p>
      Ao clicar no botão "Iniciar a Experiência",você ou a organização ou indivíduo que representa estão 
      incondicionalmente a consentir em ficar vinculados e estão a tornar-se parte neste acordo ("<b>Acordo</b>"), 
      incluindo todos os termos aqui presentes, além dos Termos de Utilização acessíveis a coty.com, que 
      estão incorporadas neste documento, com a Coty Inc., as suas marcas, licenciantes, licenciados, 
      subsidiárias, filiais, prestadores de serviços, empreiteiros, parceiros,  embaixadores da marca para 
      a campanha  Gucci Flora e cada um dos seus respetivos diretores, colaboradores, cessionários, 
      sucessores de interesse, agentes e representantes (individualmentea <b>"Parte Relacionada"</b> e 
      coletivamente, as <b>"Partes Relacionadas </b>").

      </p>
      <p>
      Está a permitir que  as partes relacionadas  utilizem a  sua  imagem para criar uma obra de arte  ("<b>Conteúdo doUtilizador<b>"). 
      Se estiver a celebrar este Acordo em nome de uma organização ou de um indivíduo, representa que tem autoridade para o fazer.
      </p>
    `,
  },
  {
    title: '3. DADOS PESSOAIS',
    // language=HTML
    content: `
      <p>
      Compreende e concorda que os seus dados  pessoais,  constituídos  pela  sua   and email addressimagem,  serãou-sed  pelas  Partes Relacionadas   para criar  e enviar-lhe  o Conteúdo do Utilizador  e serão eliminados uma vez concluído. 
      </p>
      <p>
      A nossa Política de Privacidade, disponível na <a href='https://privacy.coty.com/'
                                            target='_blank'>https://privacy.coty.com/</a>,
                                            explica os seus direitos ao abrigo das leis de proteção de dados aplicáveis.
      </p>
      <p>
      Entende que os seus dados pessoais serão totalmente destruídos após 48 (48) horas a partir da data de recolha.
      </p>
    `,
  },
  {
    title: '4. ALTERAÇÕES A ESTES TERMOS ',
    // language=HTML
    content: `
      <p>
      Na medida do permitido pela lei aplicável na sua jurisdição, a Coty reserva-se o direito de modificar ou atualizar estes Termos a qualquer momento sem aviso prévio e a sua utilização continuada da Experiência após a Coty publicar quaisquer Termos revistos significa que concorda em ficar vinculado a tais modificações ou atualizações. 
      </p>
      <p>Se algum destes Termos for considerado inválido, nulo ou, por qualquer motivo inexequível, esse termo ou condição será considerado ílquico e não afetará a validade e a execubilidade de quaisquer termos e condições restantes.
      </p>
      <p>
      Se em qualquer momento não pretender aceitar os Termos ou os Termos revistos, poderá não utilizar o Photobooth. 
    `,
  },
  {
    title: '5. PROPRIEDADE INTELECTUAL',
    // language=HTML
    content: `
      <p>
      Coty detém todos e quaisquer direitos de propriedade intelectual no Photoboothno material publicado no  Photobooth por Coty ou em seu nome, bem como em qualquer conteúdo do  Utilizador.
      </p>
      Ao utilizar o  Photoboothnão adquiriu quaisquer direitos de qualquer tipo na Experiência e/ou no Conteúdo do Utilizador que não seja o direito limitado de utilizar o  Photobooth  de acordo com estes Termos.
      </p>
      <p>
      Para além do estritamente permitido nos Termos e da utilização pretendida e normal da Photoboothnão pode copiar, reproduzir, recompiler, descompiler, desmontar, distribuir, publicar, exibir, executar, modificar, carregar para criar obras derivadas de, transmitir, em qualquer caso engenheiro inverso e/ou de qualquer outra forma explorar qualquer parte da  Photobooth  e/ou o seu Conteúdo.
      </p>
      <p>
      Representa e garante que não fará qualquer uso comercial direto ou indireto doontent do Utilizador  Cou descredibilizará Coty ou qualquer das partes relacionadas.
      </p>
    `,
  },
  {
    title: '6. GARANTIA E LIMITAÇÃO DE RESPONSABILIDADE',
    // language=HTML
    content: `
      <p>
      O Photobooth  é fornecido pela Coty numa base "como está" e "conforme disponível". Coty não faz quaisquer representações ou garantias de qualquer tipo, expressas ou implícitas, quanto à utilização do Photobooth  ou ao Conteúdo do Utilizador. Concorda expressamente que o seu uso do Photobooth é o seu único risco.
      </p>
      <p>
      Na medida do permitido pela lei aplicável, a Coty declina todas as garantias, expressas ou implícitas, incluindo, mas não se limitando a, garantias implícitas de mercadotização e aptidão para um propósito específico. 
      </p>
      <p>
      A Coty não será responsável por quaisquer danos de qualquer tipo decorrentes da utilização do Photoboothincluindo, mas não se limitando a danos diretos, indiretos, incidentais, punitivos e consequentes.
      </p>
    `,
  },
  {
    title: '7. INDEMNIDADE',
    // language=HTML
    content: `
      <p>
      Indemnizará e responsabilizará inofensivamente Coty e  as partes coliditas  de e contra todas e quaisquer coimas, sanções, responsabilidades, perdas e outros danos de qualquer tipo (incluindo honorários de advogados e peritos), incorridos pelas partes coliditas,e defenderá as partes coliditas  contra todas e quaisquer reclamações decorrentes de:
      </p>
      <ol>
        <li>o seu uso do Photobooth</li>
        <li>a sua violação destes Termos;</li>
        <li>fraude que comete, ou a sua má conduta intencional ou negligência grosseira</li>
      </ol>
      <p>
      A Parte Colidída aplicável controlará a defesa de qualquer reclamação a que esta indemnização possa aplicar-se, e em qualquer caso, não liquidará qualquer reclamação sem a aprovação prévia por escrito de tal parte relacionada.
      </p>
      <p>
      Esta disposição é considerada sem prejuízo do direito à indemnização e de quaisquer danos que possam ser reclamados de outra forma pelas partes colidtuais  em caso de rutura da presentes condições.
      </p>
    `,
  },
  {
    title: '8. ENTIRE AGREEMENT AND GOVERNING LAW',
    // language=HTML
    content: `
      <p>
      Estes Termos, incluindo a Política de Privacidade Coty localizada em <a href='https://privacy.coty.com/en_gb/'
                                                                     target='_blank'>https://privacy.coty.com/en_gb/</a>
                                                                     e quaisquer termos adicionais que possa celebrar com a Coty em relação à utilização do  Photoboothconstituirão todo o acordo entre si e a Coty.
      </p>
      <p>
      Na medida do permitido por lei, qualquer disputa de qualquer tipo que possa surgir entre si e Coty da utilização do Photoboothrege-se pela lei francesa. Na medida do permitido por lei, o senhor concorda expressamente que qualquer processo decorrente ou relacionado com a sua utilização da Experiência será instaurado perante os Tribunais de Paris.
      </p>
    `,
  },
  {
    title: '9. CONTATE-NOS ',
    // language=HTML
    content: `
      <p>
      Se tiver alguma dúvida ou comentário a respeito destes Termos, send an email via <a
        href='https://coty.cotyconsumeraffairs.com/' target='_blank'>https://coty.cotyconsumeraffairs.com/</a>
      </p>
    `,
  },
]

export default [
  {
    title: '1. INTRODUCCIÓN',
    //language=HTML
    content: `
      <p>
        Le agradecemos que utilice el Gucci Flora Purikura, que es un dispositivo de fotomatón (el “<b>Purikura</b>”).
        Le rogamos que lea detenidamente las presentes condiciones (“<b>Condiciones</b>”) antes de utilizar el Purikura, que es operado por Coty SAS, una sociedad francesa de acción simplificada con domicilio social en
        14, rue duQuatre Septembre, 75002 Paris, FRANCIE, rregistrada en el registro de comercio y empresas de París con el número 394 710 552 (“<b>COTY</b>”).
      </p>
      <p>
        Al utilizar Purikura, usted confirma que acepta estas Condiciones y que se compromete a respetarlas. 
        Si no está de acuerdo con estas CONDICIONES, no utilice el Purikura. .
      </p>`,
  },
  {
    title: '2. GUCCI FLORA PURIKURA',
    //language=HTML
    content: `
      <p> El Purikura es un dispositivo que le permite tomar una fotografía y crear un montaje fotográfico sobre el tema de la campaña Flora y compartirlo en línea o registrarlo en su teléfono
       (la Experiencia). Para participar en la Experiencia, deberá seguir los siguientes pasos:  </p>
      <ul>
        <li>Al llegar a la página de inicio, toque la pantalla para iniciar la Experiencia;</li>
        <li>Toque la fragancia Flora para tomar la foto, luego tendrá que tomar otras tres fotos para completar las flores del fondo;
        </li>
        <li> Una vez tomadas las cuatro fotos, podrás añadir pegatinas a tu fotomontaje;</li>
        <li>Valida para terminar la experiencia;</li>
        <li>Mantén pulsado para que aparezcan tus opciones ("Guarda tu foto en tu teléfono o compártela en tus plataformas sociales")
        </li>
      </ul>
      <p>
        Al hacer clic en el botón "Comenzar la experiencia", usted o la organización o persona a la que representa consienten incondicionalmente en quedar vinculados por este acuerdo 
        ("<b>Acuerdo</b>")y se convierten en parte del mismo, incluyendo todos los términos aquí expuestos, además de las Condiciones de uso accesibles en coty.com, que se incorporan 
        al presente documento, con Coty Inc, sus marcas, licenciantes, licenciatarios, filiales, proveedores de servicios, contratistas, socios, embajadores de la marca para la 
        campaña Gucci Flora y cada uno de sus respectivos directores, empleados, cesionarios, sucesores en interés, agentes y representantes (individualmente la “<b>Parte Relacionada</b>” 
        and collectively, the "<b>Partes Relacionadas</b>").
      </p>
      <p>
      Usted permite a las Partes Relacionadas utilizar su imagen para crear una pieza ("Contenido del usuario"). 
      Si usted suscribe este Acuerdo en nombre de una organización o individuo, declara que tiene la autoridad para hacerlo.      
      </p>
    `,
  },
  {
    title: '3. DATOS PERSONALES',
    // language=HTML
    content: `
      <p>
      Usted entiende y acepta que sus datos personales, consistentes en su imagen, serán utilizados por las Partes Relacionadas 
      únicamente para crear y enviarle el Contenido del Usuario y serán eliminados una vez que esto haya concluido. 
      </p>
      <p>
      Nuestra política de privacidad, disponible en <a href='https://privacy.coty.com/'
                                            target='_blank'>https://privacy.coty.com/</a>,
      explica sus derechos en virtud de las leyes de protección de datos aplicables.
      </p>
      <p>
      Usted entiende que sus datos personales serán destruidos completamente después de cuarenta y ocho (48) 
      horas desde la fecha de recogida. 
      </p>
    `,
  },
  {
    title: '4. CAMBIOS EN ESTAS CONDICIONES',
    // language=HTML
    content: `
      <p>
      En la medida en que lo permita la legislación aplicable en su jurisdicción, Coty se reserva el derecho de 
      modificar o actualizar estas Condiciones en cualquier momento sin previo aviso y su uso continuado de la 
      Experiencia después de que Coty publique las Condiciones revisadas significa que usted acepta quedar vinculado por dichas modificaciones o actualizaciones. 
      </p>
      <p>Si alguna de estas Condiciones se considerara inválida, nula o, por cualquier motivo, inaplicable, dicha condición se considerará separable y no afectará a 
      la validez y aplicabilidad de las restantes condiciones.
      </p>
      <p>
        Si en algún momento no desea aceptar los Términos o las Condiciones revisadas, no podrá utilizar Purikura. 
      </p>
    `,
  },
  {
    title: '5. PROPIEDAD INTELECTUAL',
    // language=HTML
    content: `
      <p>
        Coty es propietaria de todos los derechos de propiedad intelectual de la Purikura, del material publicado 
        en la Purikura por Coty o en su nombre, así como del Contenido del Usuario. 
      </p>
      <p>
      Al utilizar la Purikura, usted no adquiere ningún tipo de derecho sobre la Experiencia y/o, el Contenido del Usuario, 
      salvo el derecho limitado a utilizar la Purikura de acuerdo con estas Condiciones.
      </p>
      <p>
      Aparte de lo estrictamente permitido en las Condiciones y el uso previsto y normal del Purikura, usted no puede 
      copiar, reproducir, recompilar, descompilar, desensamblar, distribuir, publicar, mostrar, ejecutar, modificar, 
      cargar para crear obras derivadas, transmitir, en cualquier caso la ingeniería inversa y / o en cualquier otra 
      forma de explotar cualquier parte del Purikura y / o su Contenido. 
      </p>
      <p>
      Usted declara y garantiza que no hará ningún uso comercial, directo o indirecto, del Contenido del Usuario ni desprestigiará 
      a Coty o a cualquiera de las Partes Relacionadas. 
      </p>
    `,
  },
  {
    title: '6. GARANTÍA Y LIMITACIÓN DE RESPONSABILIDAD',
    // language=HTML
    content: `
      <p>
        Coty proporciona Purikura "tal cual" y "según disponibilidad". Coty no hace representaciones o garantías 
        de ningún tipo, expresas o implícitas, en cuanto al uso de la Purikura o del Contenido de Usuario. 
        Usted acepta expresamente que su uso de Purikura es bajo su propio riesgo. 
      </p>
      <p>
        En la medida en que lo permita la legislación aplicable, Coty renuncia a todas las garantías, expresas o implícitas, 
        incluidas, entre otras, las garantías implícitas de comerciabilidad e idoneidad para un fin determinado. 
      </p>
      <p>
      Coty no será responsable de ningún daño de cualquier tipo que surja del uso de Purikura, incluyendo, pero sin 
      limitarse a los daños directos, indirectos, incidentales, punitivos y consecuentes.
      </p>
    `,
  },
  {
    title: '7. INDEMNIZACIÓN',
    // language=HTML
    content: `
      <p>
      Usted indemnizará y eximirá a Coty y a las Partes Relacionadas de cualquier multa, sanción, responsabilidad, 
      pérdida y otros daños y perjuicios de cualquier tipo (incluidos los honorarios de abogados y peritos), 
      incurridos por las Partes Relacionadas, y defenderá a las Partes Relacionadas contra cualquier reclamación que surja de : 
      </p>
      <ol>
        <li>su uso de Purikura,</li>
        <li>su incumplimiento de estas Condiciones;</li>
        <li>el fraude que cometa, o su mala conducta intencionada o negligencia grave.</li>
      </ol>
      <p>
        La Parte Vinculada aplicable controlará la defensa de cualquier reclamación a la que pueda aplicarse esta 
        indemnización y, en cualquier caso, usted no podrá resolver ninguna reclamación sin la aprobación previa por escrito de dicha Parte Vinculada.
      </p>
      <p>
        Esta disposición se considerará sin perjuicio del derecho a la indemnización y a los daños y perjuicios que puedan reclamar de otro modo las 
        Partes Relacionadas en caso de incumplimiento de estas Condiciones.
      </p>
    `,
  },
  {
    title: '8. TOTALIDAD DEL ACUERDO Y DERECHO APLICABLE',
    // language=HTML
    content: `
      <p>
        Estas Condiciones, incluyendo la Política de Privacidad de Coty ubicada en  <a href='https://privacy.coty.com/en_gb/'
                                                                     target='_blank'>https://privacy.coty.com/en_gb/</a>
        y cualquier otra condición adicional que usted pueda acordar con Coty en relación con el uso de Purikura, constituirán el acuerdo completo entre usted y Coty.

      </p>
      <p>
      En la medida en que lo permita la ley, cualquier disputa de cualquier tipo que pueda surgir entre usted y Coty por el uso de Purikura, se regirá por la 
      legislación francesa. En la mayor medida permitida por la ley, usted acepta expresamente que cualquier procedimiento que surja de o esté relacionado 
      con su uso de la Experiencia, se iniciará ante los Tribunales de París.
      </p>
    `,
  },
  {
    title: '9. CONTACTAR CON NOSOTROS',
    // language=HTML
    content: `
      <p>
        Si tiene alguna pregunta o comentario en relación con estas Condiciones, póngase en contacto con nosotros a través de la siguiente página web: <a
        href='https://coty.cotyconsumeraffairs.com/' target='_blank'>https://coty.cotyconsumeraffairs.com/</a>
      </p>
    `,
  },
]

export default [
  {
    title: '1. はじめに',
    //language=HTML
    content: `
      <p>
      フォトブースデバイス「グッチ フローラ プリクラ」（以下<b>「プリクラ」</b>といいます。）をご利用いただきありがとうございます。
      プリクラをご利用いただく前に、本利用規約（以下<b>「規約」</b>といいます。）をよくお読みください。本プリクラは、14, rue du Quatre Septembre, 75002 Paris, 
      FRANCEに本社を置き、パリの商業・法人登録に番号394 710 552で登録済みのフランスの単純型株式会社であるCoty SAS（以下<b>「コティ」</b>といいます。）が運営しています。
      </p>
      <p>
      プリクラを利用することにより、利用者は、本規約を受諾し、本規約に同意したとみなされます。本規約に同意されない場合、プリクラを利用しないでください。
      </p>`,
  },
  {
    title: '2. グッチ フローラ プリクラ',
    //language=HTML
    content: `
      <p>本プリクラは、利用者が写真を撮影し、フローラキャンペーンのテーマに沿った合成写真を作成し、それをオンラインで共有したり、
      利用者の携帯電話に保存できる（以下「体験」といいます。）ようにするデバイスです。体験に参加するには、利用者は以下のステップに従うものとします。
      </p>
      <ul>
        <li>ホームページを開き、画面をタップして体験を開始します。</li>
        <li>フローラフレグランスの画像をタップしてメインとなる写真を撮影します。続いて、背景の花の中に入るサブ写真を3枚撮影します。</li>
        <li>写真が4枚揃ったら、合成写真にステッカーで装飾できます。</li>
        <li>確認して、体験を終了します。</li>
        <li>画面を長押しすると、「携帯電話に写真を保存する、またはSNSでシェアする」というオプションが選択できます。</li>
      </ul>
      <p>「タップして体験スタート」ボタンをクリックすることにより、利用者、または利用者が代理人を務める組織または個人は、コティ、
      そのブランド、ライセンサー、ライセンシー、子会社、関連会社、サービスプロバイダー、請負業者、パートナー、グッチフローラキャンペーンのブランドアンバサダー、
      ならびにこれらの各々の管理者、従業員、譲受人、権利承継人、代理人、および代表者（以下、個別に<b>「関連当事者」</b>といい、総称して<b>「関連当事者ら」</b>といいます。）
      と締結する本契約（以下<b>「契約」</b>といいます。）に無条件に同意し、本契約により、本契約の一部となるcoty.comからアクセスできるTerms of Use（利用条件）
      に加えて、本契約のすべての条件を含む本契約の当事者となります。
      </p>
      <p>
      利用者は、プリクラ写真（以下<b>「ユーザーコンテンツ」</b>といいます。）を制作にあたり、関連当事者らが利用者の画像を使用することを許可します。
      利用者が、組織または個人の代理として本契約に同意する場合、利用者は自身にその権限があることを表明します。
      
      </p>
    `,
  },
  {
    title: '3. 個人データ',
    // language=HTML
    content: `
      <p>
      利用者は、利用者の画像に含まれる個人データが、関連当事者らによってユーザーコンテンツを制作し利用者に送信するためにのみ使用され、
      これが完了した時点で削除されることを了解し、同意します。
      </p>
      <p>
        当社のプライバシーポリシー <a href='https://privacy.coty.com/'
                                            target='_blank'>https://privacy.coty.com/</a>,
        において、適用されるデータ保護法に基づく利用者の権利が説明されています。
      </p>
      <p>
        利用者は利用者の個人データが収集日から48時間後に完全に破壊されることを了解します。
      </p>
    `,
  },
  {
    title: '4. 本規約の変更',
    // language=HTML
    content: `
      <p>
      利用者の法域内の適用法により最大限に許容される限りにおいて、コティは通知することなくいつでも本規約を修正または更新する権利を留保し、
      コティが改訂した規約を掲載した後に利用者が継続して体験を利用することにより、利用者はかかる修正または更新に同意したこととなります。
      </p>
      <p>本規約のいずれかが無効または何らかの理由により法的強制力がないとみなされた場合、当該条項または条件は分離可能とみなされ、
      残存する条項の有効性および強制可能性に影響を及ぼさないものとします。
      </p>
      <p>
      本規約または改訂された規約の受諾を望まない場合はいつでも、利用者はプリクラの利用をやめることができます。
      </p>
    `,
  },
  {
    title: '5. 知的財産',
    // language=HTML
    content: `
      <p>
      コティは、プリクラ、コティまたはその代理者によりプリクラの中で公表されている題材、ユーザーコンテンツにおける一切の知的財産権を所有します。
      </p>
      <p>
      プリクラの利用により、利用者は本規約に従ってプリクラを利用する限定された権利以外の体験および／またはユーザーコンテンツ内のいかなる種類のいかなる権利も獲得しません。
      </p>
      <p>
      規約に厳密に許可されている、および意図された通常のプリクラの利用以外に、利用者はプリクラおよび／またはそのコンテンツのいかなる部分も、複写、複製、再コンパイル、
      逆コンパイル、分解、配布、発行、展示、上演、修正、派生物を生成するためのアップロード、送信することはできず、いかなる場合もリバースエンジニアリングおよび／またはその他の方法で利用することはできません。
      </p>
      <p>
      利用者は、利用者がユーザーコンテンツを直接・間接的に商業利用しないこと、コティまたは関連当事者らのいずれかの評判を落とさないことを表明し、保証します。
      </p>
    `,
  },
  {
    title: '6. 保証および責任の限定',
    // language=HTML
    content: `
      <p>
      プリクラは、原則「現状のまま」および「利用できるまま」でコティより提供されます。コティは、プリクラの利用またはユーザーコンテンツに関して、明示・黙示を問わずいかなる表明または保証も行いません。
      利用者は明示的に、利用者によるプリクラの利用は利用者単独の責任において行われることに同意します。
      </p>
      <p>
      適用法により最大限に許容される限りにおいて、コティは、商品性や特定目的適合性の黙示の保証などを含めて、明示・黙示を問わず保証の一切を否認します。
      </p>
      <p>
      コティは、直接的、間接的、付随的、懲罰的、派生的損害などを含めて、プリクラの利用から生じるいかなる種類の損害についても責任を負いません。
      </p>
    `,
  },
  {
    title: '7. 補償',
    // language=HTML
    content: `
      <p>
      利用者は、関連当事者らが蒙った一切の罰金、違約金、負債、損失その他一切の種類の損害（弁護士および専門家費用を含む）について、コティおよび関連当事者らに補償し、
      コティおよび関連当事者を免責し、以下から生じる一切の請求から関連当事者らを守るものとします。
      </p>
      <ol>
        <li>利用者によるプリクラの利用</li>
        <li>利用者による本規約の違反</li>
        <li>利用者による詐欺、故意の不正行為、または重大な過失</li>
      </ol>
      <p>
      当該関連当事者は、本補償が適用され得るいかなる請求についてもその防御を管理し、いかなる場合も利用者は、かかる関連当事者の書面による事前の承認なしにいかなる請求も解決しないものとします。
      </p>
      <p>
      本規定は、本規約に違反が生じた場合に関連当事者らにより請求され得るそれ以外の補償および損害賠償金の権利に影響を及ぼすことなく勘案されるものとします。
      </p>
    `,
  },
  {
    title: '8. 完全合意および準拠法',
    // language=HTML
    content: `
      <p>
        本規約は、<a href='https://privacy.coty.com/en_gb/'
                                                                     target='_blank'>https://privacy.coty.com/en_gb/</a>
        に掲載されたコティのプライバシーポリシーおよび利用者が、プリクラの使用に関連してコティと締結し得る追加的な条項を含めて、利用者とコティの間の完全な合意を構成するものとします。
      </p>
      <p>
      法律上許容される最大の範囲まで、利用者とコティの間でプリクラの利用から生じ得るいかなる性質の紛争も、フランスの法律が適用されるものとします。法律上許容される最大の範囲まで、
      利用者は本契約により、利用者の体験の利用から生じる、またはそれに関連するいかなる訴訟も、パリの裁判所に提起されることに明示的に同意します。
      </p>
    `,
  },
  {
    title: '9. お問い合わせ先',
    // language=HTML
    content: `
      <p>
        本規約に関するご質問・ご意見は、下記ウェブサイトよりお寄せください。<a
        href='https://coty.cotyconsumeraffairs.com/' target='_blank'>https://coty.cotyconsumeraffairs.com/</a>
      </p>
    `,
  },
]

export default [
  {
    title: '1. GIỚI THIỆU',
    //language=HTML
    content: `
      <p>
      Cám ơn bạn đã sử sụng Gucci Flora Purikura, một phòng chụp ảnh (the “<b>Purikura</b>”).
      Hãy đọc kỹ những điều khoản và điều kiện (“<b>Terms</b>”) trước khi sử dụng Purikura được điều hành bởi Coty SAS, một tổ chức của Pháp có văn phòng đặt tại số 14, rue du Quatre Septembre, 75002 Paris, Pháp, đã đăng ký thương mại của Paris với số 394 710 552 (“<b>COTY</b>”).
      </p>
      <p>
      Để sử dụng Purikura, bạn xác nhận rằng bạn đã đồng ý với các điều kiện và điều khoản này và bạn bị ràng buộc bởi chúng. Nếu bạn không đồng ý với những điều khoản này, đừng sử dụng Purikura.
      </p>`,
  },
  {
    title: '2. GUCCI FLORA PURIKURA',
    //language=HTML
    content: `
      <p> Purikura là một thiết bị cho phép bạn chụp ảnh và tạo dựng ảnh theo chủ đề của chiến dịch Flora và chia sẻ trực tuyến hoặc đăng ký trong điện thoại của bạn (the “Experience”). Để tham gia Trải nghiệm, bạn sẽ làm theo các bước sau: </p>
      <ul>
        <li> Khi đến trang chủ, chạm vào màn hình để bắt đầu trải nghiệm;</li>
        <li>Chạm vào nước hoa Flora để chụp ảnh, sau đó bạn sẽ phải chụp ba bức ảnh khác để điền vào những bông hoa ở trên màn hình;
        </li>
        <li>Sau khi bốn bức ảnh của bạn được chụp, bạn sẽ có thể thêm nhãn dán sinh động vào phần chỉnh ảnh của mình;</li>
        <li>Xác nhận để hoàn thành trải nghiệm;</li>
        <li>Nhấp và giữ để xem các tùy chọn của bạn xuất hiện (“Lưu ảnh của bạn trên điện thoại hoặc chia sẻ nó trên các nền tảng xã hội của bạn”)
        </li>
      </ul>
      <p>
        Bằng cách nhấp vào nút “Bắt đầu trải nghiệm”, bạn hoặc tổ chức hoặc cá nhân mà bạn đại diện đồng ý vô điều kiện bị ràng buộc và trở thành một bên của thỏa thuận này ("<b>Agreement</b>"),
        bao gồm tất cả các điều khoản ở đây ngoài Điều khoản sử dụng có thể truy cập tại coty.com, được hợp nhất tại đây, với Coty Inc., các thương hiệu, người cấp phép, người được cấp phép, công ty con, chi nhánh, nhà cung cấp dịch vụ, nhà thầu, đối tác, đại sứ thương hiệu cho chiến dịch Gucci Flora và từng giám đốc tương ứng của họ , nhân viên, người được chuyển nhượng, người kế nhiệm có lợi ích, đại lý và người đại diện
        (riêng lẻ là <b>"Bên liên quan"</b> và gọi chung là <b>"Các bên liên quan"</b>).
      </p>
      <p>
      Bạn đang cho phép các Bên liên quan sử dụng hình ảnh của bạn để tạo tác phẩm nghệ thuật <b>("Nội dung Người dùng")</b>. Nếu bạn tham gia Thỏa thuận này thay mặt cho một tổ chức hoặc cá nhân, bạn phải chắc chắn bạn có quyền làm như vậy.
      </p>
    `,
  },
  {
    title: '3. DỮ LIỆU CÁ NH N',
    // language=HTML
    content: `
      <p>
        Bạn hiểu và đồng ý rằng dữ liệu cá nhân bao gồm hình ảnh của bạn, sẽ được các bên liên quan sử dụng để sáng tạo và gởi cho bạn như Nội Dung Người Dùng và sẽ được xóa đi ngay sau đó.
      </p>
      <p>
      Chính sách bảo mật của chúng tôi có sẵn tại <a href='https://privacy.coty.com/'
                                            target='_blank'>https://privacy.coty.com/</a>,
        giải thích các quyền của bạn theo luật bảo vệ dữ liệu hiện hành.
      </p>
      <p>
      Bạn hiểu rằng dữ liệu cá nhân của bạn sẽ bị hủy hoàn toàn sau bốn mươi tám (48) giờ kể từ lúc được thu thập.
      </p>
    `,
  },
  {
    title: '4. THAY ĐỔI CÁC ĐIỀU KHOẢN',
    // language=HTML
    content: `
      <p>
      Trong phạm vi đầy đủ cho phép của luật hiện hành trong khu vực tài phán của bạn, Coty có quyền sửa đổi hoặc cập nhật các Điều khoản này bất kỳ lúc nào mà không cần thông báo và việc bạn tiếp tục sử dụng Trải nghiệm sau khi Coty đăng bất kỳ Điều khoản sửa đổi nào có nghĩa là bạn đồng ý bị ràng buộc bởi các sửa đổi đó hoặc các bản cập nhật.
      </p>
      <p>Nếu bất kỳ Điều khoản nào trong số này được coi là không hợp lệ, vô hiệu hoặc vì bất kỳ lý do gì không thể thực thi, thì điều khoản hoặc điều kiện đó sẽ được coi là có thể cắt bỏ được và sẽ không ảnh hưởng đến hiệu lực và khả năng thực thi của bất kỳ điều khoản và điều kiện nào còn lại.
      </p>
      <p>
      Nếu bất kỳ lúc nào bạn không muốn chấp nhận các Điều khoản hoặc các Điều khoản sửa đổi, bạn không thể sử dụng Purikura
      </p>
    `,
  },
  {
    title: '5. SỞ HỮU TRÍ TUỆ',
    // language=HTML
    content: `
      <p>
      Coty sở hữu bất kỳ và tất cả các quyền sở hữu trí tuệ trong Purikura, trong tài liệu được xuất bản trên Purikura bởi Coty hoặc nhân danh của nó, cũng như trong bất kỳ Nội dung Người dùng nào.
      </p>
      <p>
      Bằng cách sử dụng Purikura, bạn không có bất kỳ quyền nào dưới bất kỳ hình thức nào trong Trải nghiệm và / hoặc Nội dung Người dùng ngoài quyền hạn chế sử dụng Purikura theo các Điều khoản này.
      </p>
      <p>
      Ngoài những gì được cho phép nghiêm ngặt trong Điều khoản và mục đích sử dụng bình thường và có mục đích của Purikura, bạn không được sao chép, tái sản xuất, biên dịch lại, dịch ngược, tháo rời, phân phối, xuất bản, hiển thị, thực hiện, sửa đổi, tải lên để tạo ra các tác phẩm phát sinh từ, truyền tải, trong mọi trường hợp, thiết kế đối chiếu và / hoặc theo bất kỳ cách nào khác khai thác bất kỳ phần nào của Purikura và / hoặc Nội dung của nó.
      </p>
      <p>
        Bạn xác nhận và đảm bảo rằng bạn sẽ không thực hiện bất kỳ việc sử dụng thương mại trực tiếp hoặc gián tiếp nào đối với Nội dung của Người dùng hoặc làm cho Coty hoặc bất kỳ Bên liên quan nào trở nên tiêu cực.
      </p>
    `,
  },
  {
    title: '6. BẢO HÀNH VÀ GIỚI HẠN PHÁP LÝ',
    // language=HTML
    content: `
      <p>
        Purikura được Coty cung cấp trên cơ sở 'nguyên trạng' và 'sẵn có'. Coty không tuyên bố hoặc bảo đảm dưới bất kỳ hình thức nào, rõ ràng hay ngụ ý, đối với việc sử dụng Purikura hoặc đối với Nội dung Người dùng. Bạn hoàn toàn đồng ý rằng việc sử dụng Purikura do bạn tự chịu rủi ro.
      </p>
      <p>
      Trong phạm vi đầy đủ cho phép của luật hiện hành, Coty từ chối mọi bảo đảm, rõ ràng hay ngụ ý, bao gồm, nhưng không giới hạn, bảo đảm ngụ ý về khả năng bán được và tính phù hợp cho một mục đích cụ thể.
      </p>
      <p>
      Coty sẽ không chịu trách nhiệm đối với bất kỳ thiệt hại nào dưới bất kỳ hình thức nào phát sinh từ việc sử dụng Purikura, bao gồm, nhưng không giới hạn ở các thiệt hại trực tiếp, gián tiếp, ngẫu nhiên, và hậu quả mà nó mang lại.
      </p>
    `,
  },
  {
    title: '7. ĐIỀU KHOẢN BỒI THƯỜNG',
    // language=HTML
    content: `
      <p>
      Bạn sẽ phải tự bồi thường, Coty và các Bên liên quan hoàn toàn vô hại trước bất kỳ và tất cả các khoản tiền phạt, hình phạt, trách nhiệm pháp lý, tổn thất và các thiệt hại khác dưới bất kỳ hình thức nào (bao gồm phí luật sư và chuyên gia), do các Bên liên quan phải chịu và sẽ bảo vệ Các Bên liên quan chống lại bất kỳ và tất cả các khiếu nại phát sinh từ:
      </p>
      <ol>
        <li>việc bạn sử dụng Purikura,</li>
        <li>bạn vi phạm các Điều khoản này;</li>
        <li>gian lận mà bạn thực hiện, hoặc hành vi sai trái cố ý hoặc sơ suất nghiêm trọng của bạn</li>
      </ol>
      <p>
      Bên liên quan hiện hành sẽ kiểm soát việc bảo vệ bất kỳ khiếu nại nào mà khoản bồi thường này có thể áp dụng, và trong mọi trường hợp, bạn sẽ không giải quyết bất kỳ khiếu nại nào mà không có sự chấp thuận trước bằng văn bản của Bên liên quan đó.
      </p>
      <p>
      Điều khoản này sẽ được xem xét mà không ảnh hưởng đến quyền được bồi thường và bất kỳ thiệt hại nào có thể được các Bên liên quan yêu cầu khác trong trường hợp vi phạm các Điều khoản này.
      </p>
    `,
  },
  {
    title: '8. TOÀN BỘ THỎA THUẬN VÀ LUẬT ĐIỀU CHỈNH',
    // language=HTML
    content: `
      <p>
        Các điều khoản, bao gồm cả chính sách bảo mật của Coty có tại <a href='https://privacy.coty.com/en_gb/'
                                                                     target='_blank'>https://privacy.coty.com/en_gb/</a>
        và các điều khoản bổ sung nào bạn có thể ký với Coty liên quan đến việc sử dụng Purikura, sẽ cấu thành toàn bộ thỏa thuận giữa bạn và Coty.
      </p>
      <p>
      Trong phạm vi tối đa được pháp luật cho phép, mọi tranh chấp dưới bất kỳ hình thức nào có thể phát sinh giữa bạn và Coty từ việc sử dụng Purikura, sẽ được điều chỉnh bởi Luật Pháp. Trong phạm vi tối đa được pháp luật cho phép, bạn đồng ý rõ ràng rằng bất kỳ thủ tục nào phát sinh từ hoặc liên quan đến việc bạn sử dụng Experience, sẽ được tiến hành trước Tòa án Paris.
      </p>
    `,
  },
  {
    title: '9. LIÊN HỆ VỚI CHÚNG TÔI',
    // language=HTML
    content: `
      <p>
        Nếu bạn có bất kỳ câu hỏi hoặc nhận xét nào liên quan đến các điều khoản, xin hãy liên hệ với chúng tôi qua website: <a
        href='https://coty.cotyconsumeraffairs.com/' target='_blank'>https://coty.cotyconsumeraffairs.com/</a>
      </p>
    `,
  },
]

export default [
  {
    title: '1. 簡介',
    //language=HTML
    content: `
      <p>
      感謝閣下使用Gucci Flora Purikura拍照裝置 (「<b>Purikura</b>」)。Purikura由法國簡化股份公司Coty SAS (「<b>COTY</b>」) 營運 (註冊辦事處位於14, rue du Quatre Septembre, 75002 Paris, FRANCE，巴黎工商註冊編號為 394 710 552)，使用前請細閱本條款及細則 (「<b>條款</b>」)。
      </p>
      <p>
      使用Purikura，即代表閣下確認接受本條款並同意受其約束。倘閣下不同意本條款，請勿使用Purikura。
      </p>`,
  },
  {
    title: '2. GUCCI FLORA PURIKURA',
    //language=HTML
    content: `
      <p> Purikura裝置能讓閣下拍照和根據Flora企劃的主題製成拼貼相片，以供分享於網上或儲存至閣下的手機 (「<b>體驗</b>」) 。
          如欲參與體驗，閣下必須跟從以下步驟：
      <ul>
        <li>到達主頁後，請觸碰屏幕，以開始體驗；</li>
        <li>觸碰 Flora香水以拍照，然後閣下需再拍攝三張相片，用以填滿背景中的花卉
        </li>
        <li>拍畢四張照片後，閣下可於此拼貼相片上加上貼紙；</li>
        <li>確定以結束體驗；</li>
        <li>點撃並等候予閣下揀選的選項出現 (「把相片儲存至你的手機或分享至你的社交平台」)
        </li>
      </ul>
      <p>
      點撃「開始體驗」按鈕，即代表閣下或閣下所代表的機構或個人無條件地同意受本協議 (「協議」，包括本文所有條款以及於 coty.com可供查閱的《使用條款》) 約束並成為協議的一方 (謹此納入本文)，其他協議方為Coty Inc.、其品牌、牌照發出人、牌照持有人、附屬公司、聯盟公司、服務供應商、承辦商、合作夥伴、Gucci Flora企劃品牌大使，以及其各自的董事、僱員、承讓人、權益繼承人、代理人及代表 (「<b>相關方</b>」)。
      </p>
      <p>
      閣下容許相關方使用閣下的影像製成藝術作品 (「<b>用戶生成內容</b>」)。 
      倘閣下代表機構或個人訂立本協議，則閣下聲明自己具有予以容許的權力。 
      </p>
    `,
  },
  {
    title: '3. 個人資料',
    // language=HTML
    content: `
      <p>
      閣下明白並同意，由閣下影像所構成的個人資料將由相關方僅為製作和向閣下發送用戶生成內容而使用，並將於此過程完成後立即刪除。
      </p>
      <p>
      我們的私隱政策可於 <a href='https://privacy.coty.com/'
                                            target='_blank'>https://privacy.coty.com/</a>,
                                            查閱，當中闡述了閣下在適用資料保障法律中的權利。
      </p>
      <p>
      閣下明白，閣下的個人資料將於收集日期起計四十八 (48) 小時後悉數銷毀。 
      </p>
    `,
  },
  {
    title: '4. 條款變更 ',
    // language=HTML
    content: `
      <p>
      在閣下所在司法管轄區適用法律所允許的最大範圍內，Coty 保留隨時修訂或更新本條款而不作通知的權利，倘閣下在Coty發表任何經修訂條款後繼續使用體驗，即代表閣下同意受該等修訂或更新約束。 
      </p>
      <p>倘本條款任何內容被視為失效、無效或因任何理由而無法強制執行，該條款或細則會被視為可以分割，且無損任何餘下條款及細則的效力及強制執行能力。
      </p>
      <p>
      倘閣下於任何時候不欲接受本條款或經修訂條款，請勿使用Purikura。
      </p>
    `,
  },
  {
    title: '5. 知識產權',
    // language=HTML
    content: `
      <p>
      Coty擁有任何及所有有關Purikura、由Coty或其代表於Purikura公佈的材料及任何用戶生成內容的知識產權。 
      </p>
      <p>
      使用Purikura時，除根據本條款而使用Purikura的有限權利外，閣下於體驗及/或用戶生成內容並無擁有任何種類的權利。
      </p>
      <p>
      除本條款嚴格允許及Purikura既定及正常的用途外，閣下不得複印、複製、再編譯、解碼、分拆、發佈、出版、展示、演繹、修訂、上載，以製造衍生作品、傳送、以任何方式還原及/或以任何其他形式利用Purikura及/ 或其內容的任何部分。 
      </p>
      <p>
      閣下聲明並保證，閣下不會把用戶生成內容用於任何直接或間接商業用途，或導致Coty或任何相關方的聲譽受損。 
      </p>
    `,
  },
  {
    title: '6. 保證及責任限制',
    // language=HTML
    content: `
      <p>
      Purikura由 Coty在「現況」及「僅於可提供時」提供。Cotys概不對使用Purikura或用戶生成內容，發表任何種類的明示或默示聲明或保證。閣下明確同意就使用Purikura自行承擔風險。
      </p>
      <p>
      在適用法律所允許的最大範圍內，Coty就所有明示或默示保證作出免責聲明，包括但不限於商業適售性及特定目的之適用性的默示保證。
      </p>
      <p>
      Coty概不就因使用Purikura而蒙受的任何種類之任何損害承擔法律責任，包括但不限於直接、間接、偶發性、懲罰性及隨之發生的損害。
      </p>
    `,
  },
  {
    title: '7. 彌償',
    // language=HTML
    content: `
      <p>
      閣下將就以下情況，彌償及確保 Coty及相關方免受由相關方招致的任何種類之任何及所有罰金、罰款、債務、損失及其他損害 (包括律師費及專家費用)，並保障相關方免受任何及所有索償： 
      </p>
      <ol>
        <li>閣下使用Purikura； </li>
        <li>閣下違反本條款；</li>
        <li>閣下的欺詐行為、蓄意過失或嚴重疏忽</li>
      </ol>
      <p>
      適用的相關方將控制閣下保障其免受此等彌償適用之任何索償的情況，未經該相關方書面同意，閣下在任何情況下均不得支付任何索償。     
      </p>
      <p>
      適用的相關方將控制閣下保障其免受此等彌償適用之任何索償的情況，未經該相關方書面同意，閣下在任何情況下均不得支付任何索償。
      </p>
    `,
  },
  {
    title: '8. 完整協議及管轄法律',
    // language=HTML
    content: `
      <p>
      本條款 (包括於 <a href='https://privacy.coty.com/en_gb/'
                                                                     target='_blank'>https://privacy.coty.com/en_gb/</a>
                                                                      的《Coty私隱政策》及閣下就使用Purikura而與Coty訂立的任何額外條款) 構成閣下與Coty之間的完整協議。
      </p>
      <p>
      在法律所允許的最大範圍內，閣下與Coty之間就使用Purikura而可能引起的任何種類之任何爭議須受法國法律管轄。在法律所允許的最大範圍內，閣下謹此明確同意，因閣下使用體驗而引起或與之相關的任何法律程序須於巴黎的法院提出。
      </p>
    `,
  },
  {
    title: '9. 聯絡我們',
    // language=HTML
    content: `
      <p>
      倘閣下對本條款有任何疑問或意見，請透過以下網址聯絡我們：<a
        href='https://coty.cotyconsumeraffairs.com/' target='_blank'>https://coty.cotyconsumeraffairs.com/</a>
      </p>
    `,
  },
]

export default [
  {
    title: '1. PERKENALAN',
    //language=HTML
    content: `
      <p>
      Terima kasih telah menggunakan Gucci Flora Purikura yang merupakan perangkat photobooth (“<b>Purikura</b>”). 
      Harap baca dengan seksama syarat dan ketentuan ini (“<b>Syarat</b>”) sebelum menggunakan Purikura yang dioperasikan 
      oleh Coty SAS, sebuah société par action simplifiée Prancis dengan kantor yang beralamat di 14, rue du Quatre 
      Septembre, 75002 Paris, FRANCE, terdaftar dengan perdagangan dan perusahaan-perusahaan Paris dengan nomor 394 710 552 (“<b>COTY</b>”).

      </p>
      <p>
      Dengan menggunakan Purikura, Anda mengonfirmasi bahwa Anda menerima Ketentuan ini dan bahwa Anda setuju untuk terikat olehnya. 
      Jika Anda tidak setuju dengan PERSYARATAN ini, mohon jangan menggunakan Purikura.
      </p>`,
  },
  {
    title: '2. GUCCI FLORA PURIKURA',
    //language=HTML
    content: `
      <p> Purikura adalah perangkat yang memungkinkan Anda mengambil gambar dan membuat montase foto bertema kampanye 
      Flora dan membagikannya secara online atau terdaftar di ponsel Anda (“<b>Pengalaman</b>”). Untuk berpartisipasi dalam Pengalaman, 
      Anda harus mengikuti langkah-langkah berikut: </p>
      <ul>
        <li>Saat mendarat di beranda, sentuh layar untuk memulai Pengalaman;</li>
        <li>Sentuh wewangian Flora untuk mengambil gambar, Anda kemudian harus mengambil tiga gambar lainnya untuk mengisi bunga di latar belakang;
        </li>
        <li>Setelah mengambil empat gamba, Anda akan dapat menambahkan stiker ke montase foto Anda;<li>
        <li>Validasi untuk mengakhiri pengalaman;</li>
        <li>Klik dan tahan untuk melihat opsi Anda muncul (“Simpan gambar Anda di ponsel Anda atau bagikan di platform sosial Anda”)
        </li>
      </ul>
      <p>
      Dengan mengklik tombol “Mulai Pengalaman”, Anda atau organisasi atau individu yang Anda wakili setuju tanpa syarat untuk terikat 
      oleh dan menjadi pihak dalam perjanjian ini ("<b>Perjanjian</b>"), termasuk semua persyaratan di sini selain Persyaratan Penggunaan yang 
      dapat diakses di coty.com, yang dengan ini tergabung di sini, dengan Coty Inc., merek, pemberi lisensi, pemegang lisensi, anak perusahaan, 
      afiliasi, penyedia layanan, kontraktor, mitra, duta merek untuk kampanye Gucci Flora dan masing-masing direkturnya , karyawan, penerima hak, 
      penerus kepentingan, agen, dan perwakilan (secara individu disebut “<b>Pihak Terkait</b>” dan secara bersama-sama disebut “<b>Pihak Terkait</b>”).
      </p>
      <p>
      Anda mengizinkan Pihak Terkait untuk menggunakan gambar Anda untuk membuat karya seni ("<b>Konten Pengguna</b>").
      Jika Anda memasuki Perjanjian ini atas nama organisasi atau individu, Anda menyatakan bahwa Anda memiliki wewenang untuk melakukannya.
      
      </p>
    `,
  },
  {
    title: '3. DATA PRIBADI',
    // language=HTML
    content: `
      <p>
      Anda memahami dan menyetujui bahwa data pribadi Anda yang terdiri dari gambar Anda, akan digunakan oleh Pihak Terkait hanya untuk membuat 
      dan mengirimkan Konten Pengguna kepada Anda dan akan dihapus setelah ini selesai.
      </p>
      <p>
      Anda memahami dan menyetujui bahwa data pribadi Anda yang terdiri dari gambar Anda, akan digunakan oleh Pihak Terkait hanya untuk membuat dan mengirimkan 
      Konten Pengguna kepada Anda dan akan dihapus setelah ini selesai. <a href='https://privacy.coty.com/'
                                            target='_blank'>https://privacy.coty.com/</a>,
      yang menjelaskan hak Anda berdasarkan undang-undang perlindungan data yang berlaku.
      </p>
      <p>
      Anda memahami bahwa data pribadi Anda akan dimusnahkan sepenuhnya setelah empat puluh delapan (48) jam sejak tanggal pengumpulan.
      </p>
    `,
  },
  {
    title: '4. PERUBAHAN KETENTUAN INI',
    // language=HTML
    content: `
      <p>
      Sejauh diizinkan oleh undang-undang yang berlaku di yurisdiksi Anda, Coty berhak untuk mengubah atau memperbarui Ketentuan ini kapan saja 
      tanpa pemberitahuan dan jika Anda terus menggunakan Pengalaman setelah Coty memposting Ketentuan yang direvisi berarti Anda setuju untuk terikat oleh modifikasi atau pembaruan.
      </p>
      <p>Jika salah satu dari Ketentuan ini dianggap tidak sah, batal, atau karena alasan apa pun tidak dapat diterapkan, syarat atau ketentuan tersebut akan dianggap dapat dipisahkan dan tidak akan memengaruhi validitas dan keberlakuan syarat dan ketentuan yang tersisa.
      </p>
      <p>
      Jika sewaktu-waktu Anda tidak ingin menerima Persyaratan atau Persyaratan yang direvisi, Anda tidak boleh menggunakan Purikura.
      </p>
    `,
  },
  {
    title: '5. KEKAYAAN INTELEKTUAL',
    // language=HTML
    content: `
      <p>
      Coty memiliki setiap dan semua hak kekayaan intelektual di Purikura, dalam materi yang diterbitkan di Purikura oleh Coty atau atas namanya, serta dalam Konten Pengguna mana pun.

      </p>
      <p>
      Dengan menggunakan Purikura, Anda tidak memperoleh hak apa pun dalam Pengalaman dan/atau, Konten Pengguna selain hak terbatas untuk menggunakan Purikura sesuai dengan Ketentuan ini.
      </p>
      <p>
      Selain diizinkan secara ketat dalam Ketentuan dan penggunaan Purikura yang dimaksudkan dan normal, Anda tidak boleh menyalin, mereproduksi, mengkompilasi ulang, mendekompilasi, membongkar, mendistribusikan, menerbitkan, menampilkan, melakukan, memodifikasi, mengunggah untuk membuat karya turunan dari, mengirimkan, dalam hal apa pun merekayasa balik dan/atau dengan cara lain mengeksploitasi bagian mana pun dari Purikura dan/atau Kontennya.
      </p>
      <p>
      Anda menyatakan dan menjamin bahwa Anda tidak akan membuat penggunaan komersial langsung atau tidak langsung dari Konten Pengguna atau membawa Coty atau Pihak Terkait ke dalam keburukan.
      </p>
    `,
  },
  {
    title: '6. JAMINAN DAN PEMBATASAN TANGGUNG JAWAB',
    // language=HTML
    content: `
      <p>
      Purikura disediakan oleh Coty atas dasar 'apa adanya' dan 'sebagaimana tersedia'. Coty tidak membuat pernyataan atau jaminan apapun, tersurat maupun tersirat, mengenai penggunaan Purikura atau Konten Pengguna. Anda secara tegas setuju bahwa penggunaan Anda atas Purikura adalah risiko Anda sendiri.
      </p>
      <p>
      Sejauh diizinkan oleh hukum yang berlaku, Coty menyangkal semua jaminan, tersurat maupun tersirat, termasuk, namun tidak terbatas pada, jaminan tersirat tentang kelayakan untuk diperdagangkan dan kesesuaian untuk tujuan tertentu.
      </p>
      <p>
      Coty tidak akan bertanggung jawab atas segala kerusakan dalam bentuk apa pun yang timbul dari penggunaan Purikura, termasuk, namun tidak terbatas pada, kerusakan langsung, tidak langsung, insidental, hukuman, dan konsekuensial.
      </p>
    `,
  },
  {
    title: '7. GANTI RUGI',
    // language=HTML
    content: `
      <p>
      Anda akan mengganti kerugian dan membebaskan Coty dan Pihak Terkait dari dan terhadap setiap dan semua denda, penalti, kewajiban, kerugian dan kerusakan lainnya dalam bentuk apapun (termasuk biaya pengacara dan ahli), yang dikeluarkan oleh Pihak Terkait, dan akan membela Pihak Terkait terhadap setiap dan semua tuntutan yang timbul dari :
      </p>
      <ol>
        <li>penggunaan Purikura oleh Anda,</li>
        <li>pelanggaran Anda terhadap Ketentuan ini,</li>
        <li>penipuan yang Anda lakukan, atau kesalahan yang disengaja atau kelalaian yang sengaja.</li>
      </ol>
      <p>
      Pihak Terkait yang terkait akan mengontrol pembelaan atas klaim apa pun yang dapat dikenakan ganti rugi ini, dan dalam hal apa pun, Anda tidak akan menyelesaikan klaim apa pun tanpa persetujuan tertulis sebelumnya dari Pihak Terkait tersebut.
      </p>
      <p>
        This provision shall be considered without prejudice to the right to compensation and to any damages
        which may be claimed otherwise by the Related Parties in case of a break of these Terms.
      </p>
    `,
  },
  {
    title: '8. KESELURUHAN PERJANJIAN DAN HUKUM YANG MENGATUR',
    // language=HTML
    content: `
      <p>
      Ketentuan ini, termasuk Kebijakan Privasi Coty yang terdapat di  <a href='https://privacy.coty.com/en_gb/'
                                                                     target='_blank'>https://privacy.coty.com/en_gb/</a>
      dan ketentuan tambahan apa pun yang Anda buat dengan Coty sehubungan dengan penggunaan Purikura, akan merupakan keseluruhan perjanjian antara Anda dan Coty.

      </p>
      <p>
      Sejauh diizinkan oleh hukum, setiap perselisihan apa pun yang mungkin timbul antara Anda dan Coty dari penggunaan Purikura, akan diatur oleh Hukum Prancis. Sejauh diizinkan oleh hukum, Anda dengan ini secara tegas setuju bahwa setiap proses yang timbul dari atau terkait dengan penggunaan Pengalaman oleh Anda, akan diajukan ke Pengadilan Paris.
      </p>
    `,
  },
  {
    title: '9. HUBUNGI KAMI',
    // language=HTML
    content: `
      <p>
      Jika Anda memiliki pertanyaan atau komentar mengenai Ketentuan ini, silakan hubungi kami melalui situs web di bawah ini: <a
        href='https://coty.cotyconsumeraffairs.com/' target='_blank'>https://coty.cotyconsumeraffairs.com/</a>
      </p>
    `,
  },
]

export default [
  {
    title: 'لمقدمة: 1 ',
    //language=HTML
    content: `
      <p>
      نشكرك على استخدام جهاز جهاز غوتشي فلورا بوريكورا (<b>GUCCI FLORA PURIKURA</b>)، وهو جهاز مقصورة التصوير ("جهاز بوريكورا (PURIKURA)") يرجى قراءة هذه الشروط والأحكام ("الشروط والأحكام") قبل استخدام جهاز بوريكورا (PURIKURA) الذي تديره شركة كوتي اس ايه اس (Coty SAS)، وهي شركة فرنسية مساهمة بسيطة، ويقع عنوان مكتبها المسجل في مكتب رقم: 14، رو كواتر سبتمبري (rue du Quatre Septembre)، 75002 باريس، فرنسا (Paris, FRANCE)، ومسجلة لدى السجل التجاري والشركات في باريس (Paris) تحت رقم: 552 710 394 ("<b>شركة كوتي (Coty)</b>").
      </p>
      <p>
      وباستخدام جهاز بوريكورا (<b>PURIKURA</b>)، تؤكد على موافقتك على هذه الشروط والأحكام، وبأنك تلتزم بها، وفي حال عدم موافقتك عليها، يلزم عدم استخدام جهاز بوريكورا (PURIKURA).
      </p>`,
  },
  {
    title: '2. جهاز جهاز غوتشي فلورا بوريكورا (GUCCI FLORA PURIKURA):',
    //language=HTML
    content: `
      <p> عن جهاز بوريكورا (PURIKURA) عبارة عن جهاز يسمح بالتقاط الصور وإجراء مونتاج للصور حول موضوع حملة شركة فلورا الإعلانية ومشاركتها عبر الإنترنت أو تسجيلها في هاتفك ("التجربة")، وللمشاركة في التجربة، اتبع الخطوات التالية:</p>
      <ul>
        <li>عند فتح الصفحة الرئيسية، المس الشاشة لبدء التجربة؛</li>
        <li>المس عطر فلورا لالتقاط الصورة، ويتعين عليك التقاط ثلاثة صور أخرى لملء الزهور في الخلفية؛
        </li>
        <li>بمجرد التقاط الصور الأربعة الخاصة بك، يمكنك وضع ملصقات من أجل إجراء مونتاج لصورك؛</li>
        <li>التحقق من صحة الصور لإنهاء التجربة.</li>
        <li>انقر مع الاستمرار لرؤية خياراتك ("احفظ صورتك على هاتفك أو شاركها على منصات مواقعك الاجتماعية").
        </li>
      </ul>
      <p>
      تكون طرفًا فيها ("الاتفاقية")، بما في ذلك جميع الشروط والأحكام الواردة فيها، بالإضافة إلى شروط وأحكام الاستخدام المتاحة على الرابط التالي: coty.com، والتي يتم دمجها بموجب هذه الاتفاقية مع شركة كوتي انك (Coty Inc.)، وعلاماتها التجارية ومانحي ترخيصها والمرخص لهم من جانبها وفروعها وشركاتها التابعة ومقدمي خدماتها ومقاوليها وشركائها وسفراء علامتها التجارية في حملة غوتشي فلورا الإعلانية (Gucci Flora) وجميع مديريهم وموظفيهم والمتنازل لهم وخلفائهم في المصلحة ووكلائهم وممثليهم (يُشار إليهم منفردين باسم "الطرف ذو الصلة" ومجتمعين باسم "الأطراف ذات الصلة").
      وتسمح للأطراف ذات الصلة باستخدام صورتك في إنشاء عمل فني ("محتوى المستخدم")، وفي حال كنت تبرم هذه الاتفاقية بالنيابة عن مؤسستك أو أي فرد آخر، تقر بأن لديك صلاحية القيام بذلك.      
      </p>
      <p>
      وتسمح للأطراف ذات الصلة باستخدام صورتك في إنشاء عمل فني ("محتوى المستخدم")، وفي حال كنت تبرم هذه الاتفاقية بالنيابة عن مؤسستك أو أي فرد آخر، تقر بأن لديك صلاحية القيام بذلك.
      </p>
    `,
  },
  {
    title: '3. البيانات الشخصية:',
    // language=HTML
    content: `
      <p>
      وتدرك وتوافق على استخدام البيانات الشخصية المكونة من صورتك من جانب الأطراف ذات الصلة فقط لإنشاء محتوى المستخدم، ويتم حذفها بمجرد الانتهاء من ذلك.
      </p>
      <p>
      وتشرح سياسة الخصوصية لدينا، المتاحة على الرابط التالي:<a href='https://privacy.coty.com/'
                                            target='_blank'>https://privacy.coty.com/</a>,
                            ، حقوقك بموجب قوانين حماية البيانات المعمول بها.

      </p>
      <p>
      وتدرك بأنه سيتم إتلاف بياناتك الشخصية بالكامل بعد (48) ثمانية وأربعين ساعة من تاريخ الحصول عليها.
      </p>
    `,
  },
  {
    title: '4. تعديل هذه الشروط والأحكام:',
    // language=HTML
    content: `
      <p>
      إلى أقصى حد يسمح به القانون المعمول به في اختصاصك القضائي، يحق لشركة كوتي (Coty) تعديل أو تحديث هذه الشروط والأحكام في أي وقت دون أي إخطار، واستمرارك في استخدام التجربة بعد نشر شركة كوتي (Coty) لأي شروط وأحكام معدلة، يعني موافقتك على الالتزام بتلك التعديلات أو التحديثات.

      </p>
      <p>وفي حال اعتبر أي من هذه الشروط والأحكام باطلًا أو ملغيًا أو غير قابلًا للنفاذ لأي سبب من الأسباب، يعتبر ذلك الحكم أو الشرط قابلًا للفصل ولا يؤثر على سريان أو قابلية نفاذ أي من الشروط والأحكام المتبقية.
      </p>
      <p>
      وفي حال لم تكن ترغب في قبول الشروط والأحكام أو الشروط والأحكام المعدلة، لا يجوز لك استخدام جهاز بوريكورا (PURIKURA).
      </p>
    `,
  },
  {
    title: '5. الملكية الفكرية:',
    // language=HTML
    content: `
      <p>
      تمتلك شركة كوتي (Coty) أي وجميع حقوق الملكية الفكرية في جهاز بوريكورا (PURIKURA) والمواد المنشورة فيه من جانب شركة كوتي (Coty) أو بالنيابة عنها، بالإضافة إلى أي محتوى مستخدم.

      </p>
      <p>
      وباستخدام جهاز بوريكورا (PURIKURA)، لا تحصل على أي حقوق من أي نوع في التجربة و/ أو محتوى المستخدم، بخلاف الحق المقتصر على استخدام جهاز بوريكورا (PURIKURA) وفقًا لهذه الشروط والأحكام.
      </p>
      <p>
      وبخلاف ما هو مسموح به تمامًا في هذه الشروط والأحكام، والاستخدام المقصود والعادي لجهاز بوريكورا (PURIKURA)، لا يجوز لك النسخ أو إعادة الإنتاج أو إعادة التجميع أو الترجمة العكسية أو التفكيك أو التوزيع أو النشر أو العرض أو الأداء أو التعديل أو التحميل لإنشاء أعمال مشتقة منها او إعادة إرسالها، وبأي حال من الأحوال، القيام بالهندسة العكسية و/ أو استغلال أي جزء من جهاز بوريكورا (PURIKURA) و/ أو محتواه بأي طريقة أخرى.
      وتقر وتضمن بأنك لن تقوم بأي استخدام تجاري ومباشر أو غير مباشر لمحتوى المستخدم أو تشويه سمعة شركة كوتي (Coty) أو أي من الأطراف ذات الصلة.
      
      </p>
      <p>
      وتقر وتضمن بأنك لن تقوم بأي استخدام تجاري ومباشر أو غير مباشر لمحتوى المستخدم أو تشويه سمعة شركة كوتي (Coty) أو أي من الأطراف ذات الصلة.

      </p>
    `,
  },
  {
    title: 'الضمان وحدود المسؤولية: 6',
    // language=HTML
    content: `
      <p>
      يتم إتاحة جهاز بوريكورا (PURIKURA) بواسطة شركة كوتي (Coty) على أساس "كما هو" و"حسب توافره"، ولا تقدم شركة كوتي (Coty) أي إقرارات أو ضمانات من أي نوع، سواء صريحة أو ضمنية، فيما يتعلق باستخدام جهاز بوريكورا (PURIKURA) أو محتوى المستخدم، وتوافق صراحةً على أن يكون استخدامك لجهاز بوريكورا (PURIKURA) على مسؤوليتك وحدك.
      </p>
      <p>
      وإلى أقصى حد مسموح به بموجب القانون المعمول به، تخلي شركة كوتي (Coty) مسؤوليتها من جميع الضمانات، سواء الصريحة أو الضمنية، بما في ذلك على سبيل المثال لا الحصر الضمانات الضمنية الخاصة بالتسويق والملاءمة لغرض معين.

      </p>
      <p>
      ولا تكون شركة كوني مسؤولة عن أي أضرار من أي نوع الناشئة عن استخدام جهاز بوريكورا (PURIKURA)، بما في ذلك على سبيل المثال لا الحصر، الأضرار المباشرة وغير المباشرة والعرضية والعقابية والتبعية.
      </p>
    `,
  },
  {
    title: 'التعويض: 7',
    // language=HTML
    content: `
      <p>
      تلتزم بتعويض وإبراء شركة كوتي (Coty) والأطراف ذات الصلة من وضد أي وجميع الغرامات والعقوبات والالتزامات والخسائر والأضرار الأخرى مهما كان نوعها (بما في ذلك أتعاب المحاماة وأجور الخبراء)، التي تتكبدها الأطراف ذات الصلة، وتدافع عن الأطراف ذات الصلة ضد أي وجميع المطالبات الناشئة عن:
      </p>
      <ol>
        <li>استخدامك لجهاز بوريكورا (PURIKURA).</li>
        <li>انتهاكك لهذه الشروط والأحكام.</li>
        <li>الاحتيال الذي ترتكبه أو سوء سلوكك المتعمد أو إهمالك الجسيم.</li>
      </ol>
      <p>
      ويراقب الطرف ذو الصلة المعني الدفاع في أي مطالبة التي قد ينطبق عليها هذا التعويض، وفي أي حال، لا يجوز لك تسوية أي مطالبة دون الحصول على موافقة الطرف ذي الصلة المعني الخطية المسبقة.
      </p>
      <p>
      ويتم النظر في هذا الحكم دون المساس بالحق في التعويض وأي أضرار قد يتم المطالبة بها بخلاف ذلك من جانب الأطراف ذات الصلة في حالة انتهاك هذه الشروط والأحكام.
      </p>
    `,
  },
  {
    title: 'الجب والحلول والقانون الحاكم: 8',
    // language=HTML
    content: `
      <p>
      تشكل هذه الشروط والأحكام، بما في ذلك سياسة الخصوصية الخاصة بشركة كوتي (Coty) المتاحة عل الرابط التالي: <a
      href='https://pivacy.coty.com/' target='_blank'>https://pivacy.coty.com/</a>، وأي شروط وأحكام إضافية قد تقوم بإبرامها مع شركة كوتي (Coty) فيما يتعلق باستخدام جهاز بوريكورا (PURIKURA)، كامل الاتفاقية المبرمة بينك وبين شركة كوتي (Coty).
      </p>
      <p>
      وإلى أقصى حد مسموح به بموجب القانون، يخضع أي نزاع قد ينشأ بينك وبين شركة كوتي (Coty) من استخدام جهاز بوريكورا (PURIKURA) إلى القانون الفرنسي، وإلى أقصى حد مسموح به بموجب القانون، توافق صراحة بموجب هذه الاتفاقية على أن أي إجراء ينشأ أو يتعلق باستخدامك التجربة، يتم تقديمه أمام محاكم باريس.
      </p>
    `,
  },
  {
    title: 'اتصل بنا: 9',
    // language=HTML
    content: `
      <p>
      في حال كان لديك أي تساؤلات أو ملاحظات بخصوص هذه الشروط والأحكام، يرجى التواصل معنا عبر الموقع الإلكتروني التالي: <a
        href='https://coty.cotyconsumeraffairs.com/' target='_blank'>https://coty.cotyconsumeraffairs.com/</a>
      </p>
    `,
  },
]

export default [
  {
    title: '1. 介绍',
    //language=HTML
    content: `
      <p>
      感谢您使用Gucci Flora Purikura，这是一个互动拍照装置（“<b>Purikura</b>”）。
      在使用 Purikura之前，请仔细阅读本条款和条件（“<b>本条款</b>”。Purikura由Coty SAS研发，Coty SAS是一家法国股份有限公司，其注册地址为14, rue du Quatre Septembre,75002 Paris, FRANCE，在巴黎商业和公司管理局注册，注册号为394 710 552（“<b>COTY</b>”）。
      </p>
      <p>
      使用Purikura，您必须确认您接受本条款并同意受本条款约束。
      如果您不同意本条款，将不能使用Purikura。
      </p>`,
  },
  {
    title: '2. GUCCI FLORA PURIKURA',
    //language=HTML
    content: `
      <p> Purikura是一种装置，您可以用它拍摄Flora活动的照片和创建照片蒙太奇，并将其在线分享或存储于您的手机上（“<b>体验</b>”）。要参与该体验，您应遵循以下步骤：</p>
      <ul>
        <li>登陆首页时，触摸屏幕以启动体验；</li>
        <li>触摸Flora香氛拍照时，您需要再拍另外三张照片来填充背景中的花朵；
        </li>
        <li>一旦您的四张照片拍摄完成，您将可以在照片蒙太奇中添加贴纸；
        验证以结束体验；
        </li>
        <li>验证以结束体验；</li>
        <li>单击并按住以查看您的选项（“将您的照片保存在您的手机上或在您的社交平台上分享”）
        </li>
      </ul>
      <p>
      单击“启动体验”按钮，您或您所代表的组织或个人即无条件同意受本协议（“协议”）的约束，并将成为本协议（“<b>协议</b>”）的一方，该协议包括本协议中的所有条款以及在科蒂官方网站上可访问的使用条款，该使用条款特此并入科蒂公司、其品牌、许可方、被许可方、子公司、附属机构、服务提供商、承包商、合作伙伴、Gucci Flora活动的品牌大使及其各自的董事、员工、受让人、利益继受人、代理人和代表（单独或统称为“<b>相关方</b>”）。
      </p>
      <p>
      您允许相关方使用您的图像来创建一幅艺术作品（“<b>用户内容</b>”）。
      如果您代表某个组织或个人签署本协议，则应表明您有权签署本协议。
      </p>
    `,
  },
  {
    title: '3. 个人数据',
    // language=HTML
    content: `
      <p>
      您理解并同意，包括您图像的个人数据将仅被相关方用于创建和发送给您的用户内容，并将在此结束后被删除。
      </p>
      <p>
        <a href='https://privacy.coty.com/'
                                            target='_blank'>https://privacy.coty.com/</a> 上可以获取我们的隐私政策，用以解释您在数据保护法律下的权利。
      </p>
      <p>
      您需知悉您的个人数据将在收集之日起48小时后完全销毁。
      </p>
    `,
  },
  {
    title: '4. 对本条款的修改',
    // language=HTML
    content: `
      <p>
      在您所在司法管辖区适用法律所允许的最大范围内，科蒂保留在不经通知的情况下随时修改或更新本条款的权利，您在科蒂发布任何修改的条款后继续使用本体验表示您同意受这些修改或更新的约束。
      </p>
      <p>如果本条款中的任何条款被视为无效、失效或由于任何原因而不能执行，则该条款或条件应被视为具有可分性，不影响其他条款和条件的有效性和可执行性。
      </p>
      <p>
      如果您在任何时候不希望接受本条款或修订后的条款，则不得使用Purikura
      </p>
    `,
  },
  {
    title: '5. 知识产权',
    // language=HTML
    content: `
      <p>
      科蒂拥有以下任何及全部知识产权：Purikura、科蒂或其代表在Purikura中发布的材料以及任何用户内容。
      </p>
      <p>
      使用Purikura，您不获得任何形式的体验和/或用户内容的权利，但根据本条款使用Purikura的有限权利除外。
      </p>
      <p>
      除本条款允许以及在Purikura的预期和正常使用外，您不得复印、复制、重新编译、反编译、反汇编、分发、发表、展览、表演、修改、上传以创建演绎作品，在任何情况下进行反向工程和/或以任何其他方式利用Purikura的任何部分和/或其内容。
      </p>
      <p>
      您声明并保证，您不会对用户内容进行任何直接或间接的商业使用，或使科蒂或任何相关方名誉受损。
      </p>
    `,
  },
  {
    title: '6. 保证和责任限制',
    // language=HTML
    content: `
      <p>
      Purikura由科蒂在“按现状”和“可使用”的基础上提供。科蒂未就 Purikura的使用或用户内容作出任何类型的明示或默示的陈述或保证。您应明确同意，您对Purikura的使用自行承担风险。
      </p>
      <p>
      在适用法律允许的最大范围内，科蒂否认所有明示或默示的保证，包括但不限于对特定用途的适销性和适用性的默示保证。
      </p>
      <p>
      对于使用 Purikura产生的任何类型的损害，科蒂不承担责任，包括但不限于直接、间接、附带性、惩罚性和后果性的损害。
      </p>
    `,
  },
  {
    title: '7. 赔偿',
    // language=HTML
    content: `
      <p>
      对于科蒂和相关方发生的任何及所有罚款、处罚、责任、损失及任何类型的其他损害（包括律师费和专家费），您应赔偿科蒂和相关方并使其免受损害，并应为相关方辩护由以下原因引起的任何和所有索赔：
      </p>
      <ol>
        <li>您对Purikura的使用；</li>
        <li>您违反本条款；</li>
        <li>您实施的欺诈，或您的故意或重大过失。</li>
      </ol>
      <p>
      相关相关方将负责对本赔偿可能适用的任何索赔的辩护，在任何情况下，未经该等相关方事先书面批准，您不得解决任何索赔。
      </p>
      <p>
      本条款不应损害获得赔偿的权利，以及相关方在违反本条款的情况下可能另行要求的任何损害赔偿。
      </p>
    `,
  },
  {
    title: '8. ENTIRE AGREEMENT AND GOVERNING LAW',
    // language=HTML
    content: `
      <p>
      本条款，包括 <a href='https://privacy.coty.com/en_gb/'
                                                                     target='_blank'>https://privacy.coty.com/en_gb/</a>
                                                                     上的科蒂隐私政策以及您与科蒂就使用Purikura可能达成的任何附加条款，应构成您与科蒂之间的完整协议。
                                                                     在法律允许的最大范围内，您与科蒂之间因使用Purikura而可能产生的任何类型的争议，应受法国法律管辖。
      </p>
      <p>
      在法律允许的最大范围内，您在此应明确同意，由您使用该体验所引起或与之相关的任何诉讼均应在巴黎法院提起。
      </p>
    `,
  },
  {
    title: '9. 联系我们',
    // language=HTML
    content: `
      <p>
      如果您对本条款有任何问题或意见，请通过以下网站与我们联系： <a
        href='https://coty.cotyconsumeraffairs.com/' target='_blank'>https://coty.cotyconsumeraffairs.com/</a>
      </p>
    `,
  },
]

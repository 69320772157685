export default [
  {
    title: '1. INTRODUZIONE',
    //language=HTML
    content: `
      <p>
        La ringraziamo per l’utilizzo di Gucci Flora Purikura, uno strumento digitale photobooth (di seguito  per 
        semplicità solo "<b>Purikura</b>"). Legga attentamente i presenti termini e condizioni (di seguito i “<b>Termini</b>”) 
        prima di utilizzare Purikura, dispositivo gestito da Coty SAS, società per azioni semplificata di diritto 
        francese, con sede legale in 14, rue du Quatre Septembre, 75002 Parigi (Francia), registrata presso l'Ufficio 
        del Commercio e delle Società di Parigi al numero 394 710 552 (di seguito “<b>COTY</b>”). 
      </p>
      <p>
        Nel momento in cui utilizza Purikura Lei conferma di accettare i presenti Termini e che gli stessi sono vincolanti. 
        Qualora non intenda accettare i presenti Termini La invitiamo a non utilizzare Purikura. 
      </p>`,
  },
  {
    title: '2. GUCCI FLORA PURIKURA',
    //language=HTML
    content: `
      <p> Purikura è un dispositivo che consente di scattare una fotografia e creare un fotomontaggio che ha come 
      tema la campagna Gucci Flora e di condividerlo online o di salvarlo sul proprio telefonino (di seguito l’“<b>Esperienza</b>”). 
      Per partecipare all'Esperienza, è necessario seguire i seguenti passaggi: 
      </p>
      <ul>
        <li>una volta aperta la homepage, tocchi lo schermo per iniziare l'Esperienza;</li>
        <li>selezioni la fragranza Flora per scattare la foto e, successivamente, scatti ulteriori tre fotografie per riempire i fiori sullo sfondo;
        </li>
        <li>una volta scattate le quattro foto di cui sopra, potrà aggiungere degli adesivi/stickers al suo  fotomontaggio;</li>
        <li>convalidi per terminare l'Esperienza;</li>
        <li>clicchi e tenga premuto per visualizzare le sue opzioni (“Salva foto sul telefono o condividila sulle tue piattaforme sociali”).
        </li>
      </ul>
      <p>
        Cliccando su “Inizia l’Esperienza”, Lei o l’organizzazione o la persona che rappresenta dichiarano di accettare integralmente, 
        senza alcuna condizione, e di divenire parte di questo contratto (di seguito l’“<b>Accordo</b>”) - comprendente ogni disposizione contenuta 
        nel presente documento oltre che i Termini d’Uso accessibili su “coty.com”, da intendersi qui espressamente richiamati ed incorporati - 
        con Coty Inc., i suoi brand, licenzianti, licenziatari, filiali, affiliati, fornitori di servizi, appaltatori, partner, brand ambassadors 
        per la campagna Gucci Flora e ciascuno dei loro rispettivi direttori, dipendenti, cessionari, aventi causa, agenti e rappresentanti 
        (singolarmente la “<b>Parte Interessata</b>” e, collettivamente, le “<b>Parti Interessate</b>”). 
      </p>
      <p>
        Accettando questi Termini Lei autorizza le Parti Interessate ad utilizzare la sua immagine per creare un'opera creativa (il “<b>Contenuto Utente</b>”).
        Qualora Lei stipuli il presente Accordo per conto di una società e/o comunque di un soggetto terzo, Lei dichiara espressamente e sotto la sua responsabilità di essere legittimato a farlo. 
      </p>
    `,
  },
  {
    title: '3. DATI PERSONALI',
    // language=HTML
    content: `
      <p>
      Lei riconosce ed accetta che i suoi dati personali (in questo caso la sua sola immagine) verrà utilizzata dalle Parti Interessate al solo fine di creare ed inviarle 
      il Contenuto Utente e che l’immagine stessa verrà cancellata una volta terminato l’invio. 
      </p>
      <p>
        La nostra Privacy Policy, disponibile all'indirizzo <a href='https://privacy.coty.com/'
                                            target='_blank'>https://privacy.coty.com/</a>,
        illustra i suoi diritti in conformità ed ai sensi della normativa applicabile in materia di protezione dei dati personali.
      </p>
      <p>
      Lei riconosce ed accetta che ogni dato personale fornito sarà completamente distrutto dopo 48 (quarantotto) ore dalla data di raccolta. 
      </p>
    `,
  },
  {
    title: '4. MODIFICHE AI PRESENTI TERMINI',
    // language=HTML
    content: `
      <p>
      Nei limiti di quanto consentito dalla legge applicabile nella sua giurisdizione, COTY si riserva il diritto 
      di modificare od aggiornare in qualsiasi momento i presenti Termini senza preavviso; restando inteso che l'utilizzo 
      continuato dell’Esperienza anche dopo la pubblicazione di modifiche od aggiornamenti ai Termini ne comporterà la sua 
      piena ed espressa conoscenza ed accettazione.  
      </p>
      <p>Qualora una o più disposizioni di cui ai presenti Termini dovessero essere riconosciute invalide, nulle o inefficaci 
      per qualsiasi motivo, resta inteso che ciò non importerà la nullità o l’inefficacia di qualsivoglia restante termine o 
      condizione, che quindi resteranno validi e continueranno a produrre i loro effetti.
      </p>
      <p>
      Qualora in qualsiasi momento non intenda accettare i presenti Termini, ovvero gli stessi così come modificati, Lei non potrà utilizzare Purikura.
      </p>
    `,
  },
  {
    title: '5. PROPRIETÀ INTELLETTUALE',
    // language=HTML
    content: `
      <p>
      COTY è titolare di tutti i diritti di proprietà intellettuale su Purikura, sul relativo materiale pubblicato 
      direttamente da COTY o da terzi per suo conto, nonché su qualsiasi Contenuto Utente. 
      </p>
      <p>
      Utilizzando Purikura, Lei non acquisisce alcun diritto sull’Esperienza e/o sul Contenuto Utente, se non il 
      diritto limitato di utilizzare Purikura nei termini ed alle condizioni di cui al presente Accordo. 
      </p>
      <p>
      Al di là di quanto strettamente consentito dai Termini e/o di ciò che possa ragionevolmente considerarsi un 
      normale utilizzo di Purikura per i fini previsti da tale strumento, Lei non potrà copiare, riprodurre, ricompilare, 
      decompilare, disassemblare, distribuire, pubblicare, mostrare, eseguire, modificare, caricare al fine di creare 
      opere derivate, trasmettere, ovvero in nessun caso decodificare e/o sfruttare in qualsiasi altro modo, in tutto o in parte, Purikura e/o il suo Contenuto. 
      </p>
      <p>
      Lei dichiara e garantisce che non farà alcun uso commerciale, sia diretto che indiretto, del Contenuto Utente e che 
      non farà nulla che possa gettare discredito su COTY o su alcuna delle Parti Interessate.
      </p>
    `,
  },
  {
    title: '6. GARANZIA E LIMITAZIONE DI RESPONSABILITÀ',
    // language=HTML
    content: `
      <p>
        Purikura viene fornito da COTY “così com’è” e “se ed in quanto disponibile”. COTY non rilascia alcuna garanzia 
        e non assume alcuna responsabilità, esplicita o implicita, riguardo all’utilizzo che Lei faccia di Purikura o del 
        Contenuto Utente. Lei accetta e riconosce espressamente che ogni utilizzo di Purikura da parte sua è interamente a 
        suo rischio e sotto la sua esclusiva responsabilità. 
      </p>
      <p>
      Nei limiti consentiti dalla legge applicabile, COTY declina ogni garanzia o responsabilità, espressa od implicita, 
      tra cui - a titolo meramente esemplificativo e non esaustivo - qualsivoglia garanzia implicita di commerciabilità e 
      idoneità a fini particolari del predetto strumento. 
      </p>
      <p>
      COTY non potrà essere ritenuta responsabile di qualsivoglia eventuale danno - a qualsiasi titolo - derivante dall’uso di Purikura, 
      inclusi - ma non solo - danni diretti, indiretti, incidentali, punitivi (se previsti dalla legislazione applicabile) e consequenziali.
      </p>
    `,
  },
  {
    title: '7. INDENNIZZO',
    // language=HTML
    content: `
      <p>
      Lei si impegna a manlevare e mantenere integralmente indenni COTY e le Parti Interessate da ogni eventuale multa, 
      sanzione, responsabilità, perdite ed altri danni di qualsiasi tipo (ivi comprese eventuali spese legali e di esperti), 
      sostenute dalle Parti Interessate, e difenderà le Parti Interessate da qualsiasi eventuale reclamo derivante da: 
      </p>
      <ol>
        <li>l’utilizzo da parte sua di Purikura;</li>
        <li>la violazione da parte sua dei presenti Termini;</li>
        <li>frodi o azioni che dovesse porre in essere con dolo o colpa grave.</li>
      </ol>
      <p>
        Resta inteso che la Parte Interessata avrà il controllo della difesa di qualsiasi vertenza da cui possa 
        derivare una richiesta di indennizzo ai sensi del presente articolo; in ogni caso, non potrà transigere 
        alcuna vertenza senza la previa approvazione scritta di tale Parte Interessata.
      </p>
      <p>
        La presente disposizione non pregiudica altresì il diritto delle Parti Interessate ad essere risarcite e a 
        ottenere il ristoro di danni che possano essere altrimenti richiesti in caso di violazione dei presenti Termini.
      </p>
    `,
  },
  {
    title: '8. INTERO ACCORDO E LEGGE APPLICABILE',
    // language=HTML
    content: `
      <p>
        Questi Termini, inclusa la Coty Privacy Policy disponibile all'indirizzo  <a href='https://privacy.coty.com/'
                                                                     target='_blank'>https://privacy.coty.com/</a>
        e qualsiasi sua eventuale ulteriore pattuizione intercorsa con COTY in relazione all'utilizzo di Purikura, costituiscono l'intero accordo tra Lei e COTY.

      </p>
      <p>
      Nei limiti di cui alla legge applicabile, qualunque controversia di qualsiasi genere che dovesse insorgere tra Lei e 
      COTY a seguito dell’utilizzo di Purikura sarà regolata dalla legge francese. Nei limiti di cui alla legge applicabile, 
      Lei accetta espressamente che qualsiasi procedimento derivante o comunque relativo all'utilizzo da parte sua dell'Esperienza 
      è attribuita alla competenza esclusiva dell’Autorità giudiziaria di Parigi.
      </p>
    `,
  },
  {
    title: '9. CONTATTI',
    // language=HTML
    content: `
      <p>
        Per eventuali domande o commenti in merito ai presenti Termini si prega di contattare COTY attraverso il seguente link: <a
        href='https://coty.cotyconsumeraffairs.com/' target='_blank'>https://coty.cotyconsumeraffairs.com/</a>
      </p>
    `,
  },
]

export default [
  {
    title: '1. EINLEITUNG',
    //language=HTML
    content: `
      <p>
      Vielen Dank, dass Sie das Gucci Flora Purikura, ein Photobooth-Gerät (das "<b>Purikura</b>"), benutzen. 
      Bitte lesen Sie diese Bedingungen ("<b>Bedingungen</b>") sorgfältig durch, bevor Sie Purikura benutzen. 
      Purikura wird von Coty SAS mit Sitz in 14, rue du Quatre Septembre, 75002 Paris, FRANKREICH, 
      betrieben und ist beim Handels- und Gesellschaftsregister Paris unter der Nummer 394 710 552 ("<b>COTY</b>") 
      etragen. 
      </p>
      <p>
      Durch die Nutzung von Purikura bestätigen Sie, dass Sie diese Bedingungen akzeptieren und sich an sie gebunden fühlen. Wenn Sie mit diesen Bedingungen nicht einverstanden sind, können Sie Purikura nicht nutzen. 
      </p>`,
  },
  {
    title: '2. GUCCI FLORA PURIKURA',
    //language=HTML
    content: `
      <p> Die Purikura ist ein Gerät, das es Ihnen ermöglicht, ein Foto zu machen und eine Fotomontage zum Thema der Flora-Kampagne zu erstellen und diese online zu teilen oder in Ihrem Handy zu registrieren (das "<b>Erlebnis</b>"). Um an dem Erlebnis teilzunehmen, müssen Sie die folgenden Schritte befolgen: 
      <ul>
        <li>Wenn Sie auf der Homepage landen, berühren Sie den Bildschirm, um das Erlebnis zu starten;</li>
        <li>Berühren Sie den Flora-Duft, um das Foto zu machen. Sie müssen dann drei weitere Fotos machen, um die Blumen im Hintergrund auszufüllen;
        </li>
        <li>Sobald Sie vier Bilder aufgenommen haben, können Sie Ihrem Bild Sticker hinzufügen;</li>
        <li>Bestätigen Sie das Bild, um das Erlebnis zu beenden;</li>
        <li>Klicken und halten Sie das Bild, um Ihre Optionen einzusehen ("Drücke und halte das Bild, um es in der Bibliothek zu speichern oder teile es in den sozialen Netzwerken.")
        </li>
      </ul>
      <p>
      Indem Sie auf die Schaltfläche "Erlebnis starten" klicken, stimmen Sie oder die Organisation oder Einzelperson, 
      die Sie vertreten, bedingungslos zu, an diese Vereinbarung ("<b>Vereinbarung</b>") gebunden zu sein und werden 
      Vertragspartner dieser Vereinbarung, einschließlich aller hierin enthaltenen Bedingungen zusätzlich zu 
      den unter coty.com zugänglichen Nutzungsbedingungen, die hiermit in diese Vereinbarung aufgenommen 
      werden, mit Coty Inc, ihren Marken, Lizenzgebern, Lizenznehmern, Tochtergesellschaften, verbundenen 
      Unternehmen, Dienstleistern, Auftragnehmern, Partnern, Markenbotschaftern für die Gucci Flora Kampagne 
      und ihren jeweiligen Geschäftsführern, Mitarbeitern, Bevollmächtigten, Rechtsnachfolgern, Agenten und 
      Vertretern (einzeln die "<b>Verbundene Partei</b>" und zusammen die "<b>Verbundenen Parteien</b>"). 
      </p>
      <p>
      Sie erlauben den Verbundenen Parteien, Ihr Bild zu verwenden, um ein Bildwerk zu erstellen ("<b>Benutzerinhalte</b>"). 
      Wenn Sie diesen Vertrag im Namen einer Organisation oder einer Einzelperson abschließen, versichern Sie, dass Sie dazu befugt sind.
      
      </p>
    `,
  },
  {
    title: '3. PERSÖNLICHE DATEN',
    // language=HTML
    content: `
      <p>
      Sie verstehen und erklären sich damit einverstanden, dass Ihre persönlichen Daten, die aus Ihrem Bild bestehen, von den verbundenen Parteien nur für die Erstellung und Zusendung der Nutzerinhalte verwendet werden und nach Abschluss dieser Arbeiten gelöscht werden.
      </p>
      <p>
      Unsere Datenschutzrichtlinie, die unter <a href='https://privacy.coty.com/'
                                            target='_blank'>https://privacy.coty.com/</a>
                                            abrufbar ist, erläutert Ihre Rechte nach den geltenden Datenschutzgesetzen.
      </p>
      <p>
      Sie sind sich darüber im Klaren, dass Ihre personenbezogenen Daten nach achtundvierzig (48) Stunden ab dem Zeitpunkt der Erfassung vollständig vernichtet werden.
      </p>
    `,
  },
  {
    title: '4. ÄNDERUNG DER BEDINGUNGEN',
    // language=HTML
    content: `
      <p>
      Coty behält sich das Recht vor, diese Bedingungen jederzeit ohne Vorankündigung zu ändern oder zu aktualisieren, soweit dies nach geltendem Recht in Ihrer Gerichtsbarkeit zulässig ist, und Ihre fortgesetzte Nutzung der Erfahrung, nachdem Coty überarbeitete Bedingungen veröffentlicht hat, bedeutet, dass Sie sich mit diesen Änderungen oder Aktualisierungen einverstanden erklären.
      </p>
      <p>Sollte eine dieser Bedingungen als ungültig, nichtig oder aus irgendeinem Grund als nicht durchsetzbar erachtet werden, gilt diese Bedingung als nichtig und berührt nicht die Gültigkeit und Durchsetzbarkeit der verbleibenden Bedingungen und Konditionen.
      </p>
      <p>
      Sollte eine dieser Bedingungen als ungültig, nichtig oder aus irgendeinem Grund als nicht durchsetzbar erachtet werden, gilt diese Bedingung als nichtig und berührt nicht die Gültigkeit und Durchsetzbarkeit der verbleibenden Bedingungen und Konditionen.
      </p>
    `,
  },
  {
    title: '5. GEISTIGES EIGENTUM',
    // language=HTML
    content: `
      <p>
      Coty ist Eigentümer aller geistigen Eigentumsrechte an Purikura, an dem von Coty oder in seinem Namen in Purikura veröffentlichten Material sowie an den Benutzerinhalten. 
      </p>
      <p>
      Durch die Nutzung von Purikura erwerben Sie keinerlei Rechte an der Erfahrung und/oder den Benutzerinhalten, mit Ausnahme des beschränkten Rechts, Purikura in Übereinstimmung mit diesen Bedingungen zu nutzen.
      </p>
      <p>
      Außer wie in den Bedingungen und der beabsichtigten und normalen Nutzung von Purikura ausdrücklich erlaubt, dürfen Sie keinen Teil von Purikura und/oder dessen Inhalt kopieren, nachbilden, rekompilieren, dekompilieren, disassemblieren, vertreiben, veröffentlichen, ausstellen, vorführen, modifizieren, hochladen, um davon abgeleitete Werke zu erstellen, übertragen, in jedem Fall zurückentwickeln und/oder in irgendeiner anderen Weise verwerten.
      </p>
      <p>
      Sie versichern und garantieren, dass Sie keine direkte oder indirekte kommerzielle Nutzung der Benutzerinhalte vornehmen oder Coty oder eine der verbundenen Parteien in Verruf bringen werden.
      </p>
    `,
  },
  {
    title: '6. GARANTIE UND HAFTUNGSAUSSCHUSS',
    // language=HTML
    content: `
      <p>
      Purikura wird von Coty auf einer "wie besehen" und "wie verfügbar" Basis zur Verfügung gestellt. Coty gibt keinerlei ausdrückliche oder stillschweigende Zusicherungen oder Gewährleistungen in Bezug auf die Nutzung von Purikura oder die Benutzerinhalte. Sie erklären sich ausdrücklich damit einverstanden, dass Ihre Nutzung von Purikura auf Ihr eigenes Risiko erfolgt.
      </p>
      <p>
      Coty lehnt, soweit dies nach geltendem Recht zulässig ist, alle ausdrücklichen oder stillschweigenden Garantien ab, einschließlich, aber nicht beschränkt auf stillschweigende Garantien der Marktgängigkeit und Eignung für einen bestimmten Zweck.
      </p>
      <p>
      Coty haftet nicht für Schäden jeglicher Art, die aus der Verwendung von Purikura entstehen, einschließlich, aber nicht beschränkt auf direkte, indirekte, zufällige, strafende und Folgeschäden.
      </p>
    `,
  },
  {
    title: '7. SCHADENSERSATZ',
    // language=HTML
    content: `
      <p>
      Sie stellen Coty und die Verbundenen Parteien von allen Bußgeldern, Strafen, Haftungen, Verlusten und anderen Schäden jeglicher Art (einschließlich Anwalts- und Sachverständigenkosten) frei und verteidigen die Verbundenen Parteien gegen alle Ansprüche, die sich ergeben aus : 
      </p>
      <ol>
        <li>Ihrer Nutzung von Purikura, </li>
        <li>Ihrem Verstoß gegen diese Bedingungen;</li>
        <li>dem von Ihnen begangenen Betrug oder Ihrem vorsätzlichen Fehlverhalten oder Ihrer groben Fahrlässigkeit</li>
      </ol>
      <p>
      Die jeweilige Verbundene Partei hat die Kontrolle über die Verteidigung von Ansprüchen, auf die sich diese Freistellung bezieht, und in jedem Fall dürfen Sie keine Ansprüche ohne die vorherige schriftliche Zustimmung dieser Verbundenen Partei beilegen.
      </p>
      <p>
      Diese Bestimmung gilt unbeschadet des Rechts auf Entschädigung und Schadensersatz, das von den Verbundenen Parteien im Falle eines Verstoßes gegen diese Bedingungen anderweitig geltend gemacht werden kann.
      </p>
    `,
  },
  {
    title: '8. GESAMTER VERTRAG UND GELTENDES RECHT',
    // language=HTML
    content: `
      <p>
      Diese Bedingungen, einschließlich der Coty-Datenschutzrichtlinie, die sich unter  <a href='https://privacy.coty.com/en_gb/'
                                                                     target='_blank'>https://privacy.coty.com/en_gb/</a>
                                                                     befindet, und aller zusätzlichen Bedingungen, die Sie mit Coty in Verbindung mit der Nutzung von Purikura eingehen, stellen die gesamte Vereinbarung zwischen Ihnen und Coty dar.
      </p>
      <p>
      Soweit gesetzlich zulässig, unterliegen alle Streitigkeiten jeglicher Art, die zwischen Ihnen und Coty aus der Nutzung von Purikura entstehen könnten, französischem Recht. Soweit gesetzlich zulässig, erklären Sie sich hiermit ausdrücklich damit einverstanden, dass alle Verfahren, die sich aus Ihrer Nutzung der Erfahrung ergeben oder damit in Zusammenhang stehen, vor den Gerichten in Paris eingeleitet werden.
      </p>
    `,
  },
  {
    title: '9. KONTAKT',
    // language=HTML
    content: `
      <p>
      Wenn Sie Fragen oder Anmerkungen zu diesen Bedingungen haben, kontaktieren Sie uns bitte über die folgende Website: <a
        href='https://coty.cotyconsumeraffairs.com/' target='_blank'>https://coty.cotyconsumeraffairs.com/</a>
      </p>
    `,
  },
]

export default [
  {
    title: '1. INTRODUÇÃO',
    //language=HTML
    content: `
      <p>
      Obrigado por usar o Gucci Flora Purikura, que é um dispositivo de cabine fotográfica (o “<b>Purikura</b>”).
      Leia atentamente estes termos e condiçõe (“<b>Termos</b>”) antes de usar o Purikura, que é operado pela Coty SAS, 
      uma sociedade francesa por ação simplificada com sede em 14, rue duQuatre Septembre, 75002 Paris, FRANÇA, registrada 
      no comércio e as empresas de Paris sob o número 394 710 552 (“<b>COTY</b>”).
      </p>
      <p>
      Ao usar o Purikura, você confirma que aceita estes Termos e concorda em obedecer a eles. Se você não concorda com 
      estes TERMOS, não use o Purikura.
      </p>`,
  },
  {
    title: '2. GUCCI FLORA PURIKURA',
    //language=HTML
    content: `
      <p>O Purikura é um dispositivo que permite tirar uma foto e criar uma montagem fotográfica sobre o tema da 
      campanha Flora e compartilhá-la online ou cadastrada no seu celular (a “Experiência”). Para participar da 
      Experiência, você deve seguir as seguintes etapas:
      </p>
      <ul>
        <li>Ao pousar na homepage, toque na tela para iniciar o Experience;</li>
        <li>Toque na fragrância Flora para tirar a foto, então você terá que tirar outras três fotos para preencher as flores do fundo;
        </li>
        <li>Assim que suas quatro fotos forem tiradas, você poderá adicionar adesivos à sua montagem de fotos;</li>
        <li>Validar para encerrar a experiência;</li>
        <li>Clique e segure para ver suas opções aparecerem (“Salve sua foto em seu telefone ou compartilhe-a em suas plataformas sociais”)
        </li>
      </ul>
      <p>
        Ao clicar no botão “Iniciar a Experiência”, você ou a organização ou indivíduo que você representa está 
        incondicionalmente consentindo em ser vinculado e está se tornando uma parte deste contrato ("<b>Contrato</b>"),
        incluindo todos os termos aqui além dos Termos de uso acessível em coty.com, que são aqui incorporados, com a Coty Inc., 
        suas marcas, licenciados, licenciados, subsidiárias, afiliadas, prestadores de serviços, contratados, parceiros, 
        embaixadores da marca para a campanha Gucci Flora e cada um de seus respectivos diretores , empregados, cessionários, 
        sucessores de interesse, prepostos e representantes (individually the “<b>Parte Relacionada</b>” and collectively, the "<b>Partes Relacionadas</b>").
      </p>
      <p>
        Você está permitindo que as Partes Relacionadas usem sua imagem para criar uma obra de arte ("<b>Conteúdo do Usuário</b>"). 
        Se você estiver firmando este Contrato em nome de uma organização ou indivíduo, você declara que tem autoridade para fazê-lo.
      </p>
    `,
  },
  {
    title: '3. DADOS PESSOAIS',
    // language=HTML
    content: `
      <p>
      Você entende e concorda que seus dados pessoais, constituídos por sua imagem, serão utilizados pelas Partes Relacionadas 
      apenas para criar e enviar a você o Conteúdo do Usuário e serão excluídos assim que este for concluído.
      </p>
      <p>
      Nossa política de privacidade, disponível em <a href='https://privacy.coty.com/'
                                            target='_blank'>https://privacy.coty.com/</a>,
      explica seus direitos sob as leis de proteção de dados aplicáveis.
      </p>
      <p>
      Você entende que seus dados pessoais serão totalmente destruídos após quarenta e oito (48) horas a partir da data de coleta.
      </p>
    `,
  },
  {
    title: '4. ALTERAÇÕES A ESTES TERMOS',
    // language=HTML
    content: `
      <p>
      Em toda a extensão permitida pela lei aplicável em sua jurisdição, a Coty se reserva o direito de modificar 
      ou atualizar estes Termos a qualquer momento sem aviso prévio e seu uso continuado da Experiência após a Coty 
      publicar quaisquer Termos revisados ​​significa que você concorda em obedecer a tais modificações ou atualizações.
      </p>
      <p>Se qualquer um destes Termos for considerado inválido, nulo ou por qualquer motivo inaplicável, esse termo ou condição 
      será considerado separável e não afetará a validade e exequibilidade de quaisquer termos e condições restantes.
      </p>
      <p>
      Se a qualquer momento você não quiser aceitar os Termos ou os Termos revisados, você não pode usar o Purikura.
      </p>
    `,
  },
  {
    title: '5. PROPRIEDADE INTELECTUAL',
    // language=HTML
    content: `
      <p>
      A Coty detém todos e quaisquer direitos de propriedade intelectual no Purikura, no material publicado no 
      Purikura pela Coty ou em seu nome, bem como em qualquer Conteúdo do Usuário.
      </p>
      <p>
      Ao usar o Purikura, você não adquire direitos de qualquer tipo na Experiência e / ou Conteúdo do Usuário, 
      exceto o direito limitado de usar o Purikura de acordo com estes Termos.
      </p>
      <p>
      Exceto conforme estritamente permitido nos Termos e no uso pretendido e normal do Purikura, você não pode copiar, 
      reproduzir, recompilar, descompilar, desmontar, distribuir, publicar, exibir, executar, modificar, fazer upload para 
      criar trabalhos derivados de, transmitir, em qualquer caso, fazer engenharia reversa e / ou de qualquer outra forma 
      explorar qualquer parte do Purikura e / ou seu Conteúdo.
      </p>
      <p>
      Você declara e garante que não fará qualquer uso comercial direto ou indireto do Conteúdo do Usuário ou desacreditará a 
      Coty ou qualquer uma das Partes Relacionadas.
      </p>
    `,
  },
  {
    title: '6. GARANTIA E LIMITAÇÃO DE RESPONSABILIDADE',
    // language=HTML
    content: `
      <p>
      O Purikura é fornecido pela Coty 'como está' e 'conforme disponível'. A Coty não faz representações ou 
      garantias de qualquer tipo, expressas ou implícitas, quanto ao uso do Purikura ou do Conteúdo do Usuário. 
      Você concorda expressamente que o uso do Purikura é por sua conta e risco.
      </p>
      <p>
      Em toda a extensão permitida pela lei aplicável, a Coty se isenta de todas as garantias, expressas ou implícitas, 
      incluindo, mas não se limitando a, garantias implícitas de comercialização e adequação a um propósito específico.
      </p>
      <p>
      A Coty não será responsável por quaisquer danos de qualquer tipo decorrentes do uso do Purikura, incluindo, mas não 
      se limitando a di
      </p>
    `,
  },
  {
    title: '7. INDENIZAÇÃO',
    // language=HTML
    content: `
      <p>
      Você indenizará e isentará a Coty e as Partes Relacionadas de e contra todas e quaisquer multas, penalidades, responsabilidades, perdas e outros danos de qualquer tipo (incluindo honorários de advogados e especialistas), incorridos pelas Partes Relacionadas, e deverá defender as Partes Relacionadas contra toda e qualquer reclamação decorrente de:

      </p>
      <ol>
        <li>i. seu uso do Purikura</li>
        <li>ii. sua violação destes Termos;</li>
        <li>iii. fraude cometida, ou sua má conduta intencional ou negligência grave</li>
      </ol>
      <p>
      A Parte Relacionada aplicável controlará a defesa de qualquer reclamação a que esta indenização se aplique e, em qualquer caso, você não deverá resolver nenhuma reclamação sem a aprovação prévia por escrito de tal Parte Relacionada.
      </p>
      <p>
      Esta disposição será considerada sem prejuízo do direito à indenização e a quaisquer danos que possam ser reivindicados pelas Partes Relacionadas em caso de violação destes termos.
      </p>
    `,
  },
  {
    title: '8. ACORDO COMPLETO E LEI APLICÁVEL',
    // language=HTML
    content: `
      <p>
      Estes Termos, incluindo a Política de Privacidade da Coty localizada em  <a href='https://privacy.coty.com/en_gb/'
                                                                     target='_blank'>https://privacy.coty.com/en_gb/</a>
                                                                     e quaisquer termos adicionais que você possa celebrar com a Coty em relação ao uso do Purikura, constituem o acordo integral entre você e a Coty
      </p>
      <p>
      Na medida máxima permitida por lei, qualquer disputa de qualquer tipo que possa surgir entre você e a Coty devido ao uso do Purikura, será regida pela lei francesa. Em toda a extensão permitida por lei, você concorda expressamente que qualquer processo decorrente ou relacionado ao uso da Experiência deverá ser instaurado perante os Tribunais de Paris.
      </p>
    `,
  },
  {
    title: '9. CONTACTE-NOS',
    // language=HTML
    content: `
      <p>
      Se você tiver dúvidas ou comentários sobre estes Termos, entre em contato conosco através do site abaixo: <a
        href='https://coty.cotyconsumeraffairs.com/' target='_blank'>https://coty.cotyconsumeraffairs.com/</a>
      </p>
    `,
  },
]

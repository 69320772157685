export default [
  {
    title: '1. Introduction',
    //language=HTML
    content: `
      <p>
      Merci d’utiliser le Purikura Gucci Flora, qui consiste en un outil photo (le “<b>Purikura</b>”).
      Veuillez lire attentivement les présentes modalités et conditions (les “<b>conditions</b>”) avant d’utiliser le Purikura, qui est exploité par Coty SAS, société par actions simplifiée française dont le siège social est 14, rue du Quatre Septembre, 75002 Paris, FRANCE, et immatriculée au Registre du commerce et des sociétés de Paris sous le numéro 394 710 552 (“<b>COTY</b>”).
      </p>
      <p>
      En utilisant le Purikura, vous confirmez que vous acceptez les présentes conditions et convenez d’être lié par celles-ci. Si vous n’acceptez par ces CONDITIONS, n’utilisez pas le Purikura. 
      </p>`,
  },
  {
    title: '2. PURIKURA GUCCI FLORA',
    //language=HTML
    content: `
      <p> Le Purikura est un outil qui vous permet de prendre une photo, de créer un montage sous le thème de la campagne Flora, puis de le partager en ligne ou de l’enregistrer sur votre téléphone (l’« expérience »). Pour participer à l’expérience, vous devez suivre ces étapes : 
      </p>
      <ul>
        <li>À la page d’accueil, appuyer sur l’écran pour lancer l’expérience.</li>
        <li>Appuyer sur le parfum Flora pour prendre la photo, puis prendre trois autres photos pour remplir les fleurs en arrière-plan.
        </li>
        <li>Une fois les photos prises, il est possible d’ajouter des autocollants au montage photo.</li>
        <li>Valider pour terminer l’expérience.</li>
        <li>Appuyer sans relâcher pour voir les options (enregistrer la photo sur votre téléphone ou la partager sur les médias sociaux).
        </li>
      </ul>
      <p>
      En cliquant sur le bouton « Commencer l’expérience », vous, ou encore l’organisation ou la personne que vous représentez, consentez inconditionnellement à devenir partie au présent accord (l'"<b>accord</b>"),
      y compris toutes les conditions aux présentes en plus des conditions d’utilisation accessibles à coty.com, lesquelles sont incorporées ici, avec Coty Inc., ses marques, ses donneurs et preneurs de licences, ses filiales, ses sociétés affiliées, ses fournisseurs de service, ses entrepreneurs, ses partenaires, les ambassadeurs de la campagne Gucci Flora, ainsi que chacun de leurs directeurs, employés, successeurs et ayants droit, agents et représentants respectifs (individuellement, une “<b>partie liée</b>” et collectivement les "<b>parties liées</b>").
      </p>
      <p>
      Vous autorisez les parties liées à utiliser votre image pour créer une œuvre artistique (le "<b>contenu utilisateur</b>"). Si vous concluez le présent accord au nom d’une organisation ou d’une autre personne, vous reconnaissez détenir le pouvoir pour le faire.
      the authority to do so.
      </p>
    `,
  },
  {
    title: '3. DONNÉES À CARACTÈRE PERSONNEL',
    // language=HTML
    content: `
      <p>
      Vous comprenez et convenez que vos données à caractère personnel consistant en votre image seront utilisées par les parties liées dans le seul but de créer le contenu utilisateur et de vous l’envoyer, puis seront supprimées par la suite. 
      </p>
      <p>
      Notre politique de confidentialité, accessible à l’adresse <a href='https://privacy.coty.com/'
                                            target='_blank'>https://privacy.coty.com/</a>,
                                            explique vos droits en vertu des lois applicables sur la protection des données.
      </p>
      <p>
      Vous comprenez que vos données à caractère personnel seront entièrement détruites après quarante-huit (48) heures suivant la date où elles ont été recueillies. 
      </p>
    `,
  },
  {
    title: '4. CHANGEMENTS AUX PRÉSENTES CONDITIONS ',
    // language=HTML
    content: `
      <p>
      Dans toute la mesure permise par la loi en vigueur dans votre territoire de compétence, Coty se réserve le droit de modifier ou de mettre à jour ces conditions à tout moment sans préavis. En continuant à utiliser l’expérience après la publication des conditions révisées par Coty, vous acceptez d’être lié par ces modifications ou mises à jour. 
      </p>
      <p>Si l’une des présentes conditions est jugée invalide, nulle ou inapplicable pour quelque raison que ce soit, cette condition sera considérée comme dissociable et n’affectera pas la validité et l’applicabilité des autres conditions.
      </p>
      <p>
      Si, à tout moment, vous ne souhaitez pas accepter les conditions ou les conditions révisées, vous ne pouvez pas utiliser le Purikura. 
      </p>
    `,
  },
  {
    title: '5. PROPRIÉTÉ INTELLECTUELLE',
    // language=HTML
    content: `
      <p>
      Coty détient tous les droits de propriété intellectuelle sur le Purikura, sur le matériel publié dans le Purikura par Coty ou pour son compte, ainsi que sur tout contenu utilisateur. 
      </p>
      <p>
      En utilisant le Purikura, vous n’acquérez aucun droit de quelque nature que ce soit sur l’expérience et (ou) le contenu utilisateur, si ce n’est le droit limité d’utiliser le Purikura conformément aux présentes conditions.
      </p>
      <p>
      À l’exception de ce qui est strictement autorisé par les conditions et l’utilisation prévue et normale du Purikura, vous ne pouvez pas copier, reproduire, recompiler, décompiler, désassembler, distribuer, publier, afficher, exécuter, modifier, télécharger (pour créer des œuvres dérivées), transmettre, rétroconcevoir et (ou) exploiter de toute autre manière toute partie du Purikura ou de son contenu. 
      </p>
      <p>
      Vous déclarez et garantissez que vous ne ferez aucune utilisation commerciale directe ou indirecte du contenu utilisateur et que vous ne porterez pas atteinte à la réputation de Coty ou de l’une ou l’autre des parties liées. 
      </p>
    `,
  },
  {
    title: '6. GARANTIE ET LIMITATION DE RESPONSABILITÉ',
    // language=HTML
    content: `
      <p>
      Le Purikura est fourni par Coty en l’état et selon la disponibilité. Coty ne fait aucune déclaration et ne donne aucune garantie de quelque nature que ce soit, expresse ou implicite, quant à l’utilisation du Purikura ou du contenu utilisateur. Vous convenez expressément que vous utilisez le Purikura à vos propres risques. 
      </p>
      <p>
      Dans toute la mesure où la loi en vigueur le permet, Coty décline toute garantie, expresse ou implicite, y compris, mais sans s’y limiter, les garanties implicites de qualité marchande et de convenance à une fin particulière. 
      </p>
      <p>
      Coty ne sera pas responsable des dommages de toute nature résultant de l’utilisation du Purikura, y compris, mais sans s’y limiter, les dommages directs, indirects, accessoires, punitifs et consécutifs.
      </p>
    `,
  },
  {
    title: '7. INDEMNISATION',
    // language=HTML
    content: `
      <p>
      Vous indemniserez et dégagerez Coty et les parties liées de toute responsabilité à l’égard de toutes les amendes, pénalités, responsabilités, pertes et autres dommages de quelque nature que ce soit (y compris les honoraires d’avocats et d’experts), encourus par les parties liées, et assurerez la défense des parties liées contre toute réclamation découlant :  
      </p>
      <ol>
        <li>de votre utilisation du Purikura;</li>
        <li>de la violation des présentes conditions de votre part;</li>
        <li>d’une fraude que vous commettez, ou d’une faute intentionnelle ou négligence grave de votre part.</li>
      </ol>
      <p>
      La partie liée concernée contrôlera la défense de toute réclamation à laquelle cette indemnité peut s’appliquer et, dans tous les cas, vous ne pourrez régler aucune réclamation sans l’accord écrit préalable de ladite partie liée.
      </p>
      <p>
      Cette disposition est considérée sans préjudice du droit à l’indemnisation et aux dommages et intérêts qui peuvent être réclamés par ailleurs par les parties liées en cas de violation des présentes conditions.
      </p>
    `,
  },
  {
    title: '8. INTÉGRALITÉ DE L’ACCORD ET DES LOIS APPLICABLES',
    // language=HTML
    content: `
      <p>
      Les présentes conditions, y compris la politique de confidentialité de Coty accessible à l’adresse <a href='https://privacy.coty.com/fr_fr/'
                                                                     target='_blank'>https://privacy.coty.com/fr_fr/</a>
                                                                     et toutes conditions supplémentaires que vous pouvez convenir avec Coty relativement à l’utilisation du Purikura, constituent l’intégralité de l’accord entre vous et Coty.
      </p>
      <p>
      Dans toute la mesure permise par la loi, tout litige de quelque nature que ce soit qui pourrait survenir entre vous et Coty du fait de l’utilisation de l’expérience, sera régi par le droit français. Dans toute la mesure permise par la loi, vous convenez expressément que toute procédure découlant de votre utilisation de l’expérience ou s’y rapportant sera intentée devant les tribunaux français.
      </p>
    `,
  },
  {
    title: '9. NOUS JOINDRE',
    // language=HTML
    content: `
      <p>
      Si vous avez des questions ou des commentaires concernant les présentes modalités, veuillez communiquer avec nous à partir du site Web suivant : <a
        href='https://coty.cotyconsumeraffairs.com/' target='_blank'>https://coty.cotyconsumeraffairs.com/</a>
      </p>
    `,
  },
]

export default [
  {
    title: '1. Introduction',
    //language=HTML
    content: `
      <p>
        Thank you for using the Gucci Flora Purikura which is a photobooth device (the “<b>Purikura</b>”).
        Please read carefully these terms and conditions (“<b>Terms</b>”) before using the Purikura which is
        operated by Coty SAS, a French société par action simplifiée with its registered office at 14, rue du
        Quatre Septembre, 75002 Paris, FRANCE, registered with of commerce and companies of Paris under number
        394 710 552 (“<b>COTY</b>”).
      </p>
      <p>
        By using the Purikura, you confirm that you accept these Terms and that you agree to be bounded by them.
        If you do not agree to these TERMS, do not use the Purikura.
      </p>`,
  },
  {
    title: '2. GUCCI FLORA PURIKURA',
    //language=HTML
    content: `
      <p> The Purikura is a device which allows you to take a picture and create a photo montage on the theme of
        the Flora campaign and to have it shared online or registered in your phone (the “Experience”). To
        participate in the Experience, you shall follow the following steps: </p>
      <ul>
        <li>When landing on the homepage, touch the screen to start the Experience;</li>
        <li>Touch the Flora fragrance to take the picture, you will then have to take three other pictures to
          fill in the flowers in the background;
        </li>
        <li>Once your four pictures are taken, you will be able to add stickers to your photo montage;</li>
        <li>Validate to end the experience;</li>
        <li>Click and hold to see your options appear (“Save your picture on your phone or share it on your
          social platforms”)
        </li>
      </ul>
      <p>
        By clicking the “Start the Experience” button, you or the organization or individual you represent are
        unconditionally consenting to be bound by and are becoming a party to this agreement ("<b>Agreement</b>"),
        including all of the terms herein in addition to the Terms of Use accessible at coty.com, which are
        hereby incorporated herein, with Coty Inc., its brands, licensors, licensees, subsidiaries, affiliates,
        service providers, contractors, partners, brand ambassadors for the Gucci Flora campaign and each of
        their respective directors, employees, assignees, successors in interest, agents, and representatives
        (individually the “<b>Related Party</b>” and collectively, the "<b>Related Parties</b>").
      </p>
      <p>
        You are allowing the Related Parties to use your image to create an artwork ("<b>User Content</b>"). If
        you are entering this Agreement on behalf of an organization or individual, you represent that you have
        the authority to do so.
      </p>
    `,
  },
  {
    title: '3. Personal data',
    // language=HTML
    content: `
      <p>
        You understand and agree that your personal data consisting of your image, will be used by the Related
        Parties only to create and send you the User Content and will be deleted once this has concluded.
      </p>
      <p>
        Our privacy Policy, available at <a href='https://privacy.coty.com/'
                                            target='_blank'>https://privacy.coty.com/</a>,
        explains your rights under applicable data protection laws.
      </p>
      <p>
        You understand that your personal data will be fully destroyed after forty-eight (48) hours from the
        collection date.
      </p>
    `,
  },
  {
    title: '4. Change to these terms',
    // language=HTML
    content: `
      <p>
        To the full extent permissible by applicable law in your jurisdiction, Coty reserves the right to modify
        or update these Terms at any time without notice and your continued use of the Experience after Coty
        posts any revised Terms means you agree to be bound by such modifications or updates.
      </p>
      <p>If any of these Terms shall be deemed invalid, void, or for any reason unenforceable, that term or
        condition shall be deemed severable and shall not affect the validity and enforceability of any
        remaining terms and conditions.
      </p>
      <p>
        If at any time you do not wish to accept the Terms or the revised Terms, you may not use the Purikura.
      </p>
    `,
  },
  {
    title: '5. INTELLECTUAL PROPERTY',
    // language=HTML
    content: `
      <p>
        Coty owns any and all intellectual property rights in the Purikura, in the material published in the
        Purikura by Coty or on its behalf, as well as in any of User Content.
      </p>
      <p>
        By using the Purikura, you acquire no rights of any kind in the Experience and/or, the User Content
        other than the limited right to use the Purikura in accordance with these Terms.
      </p>
      <p>
        Other than as strictly permitted in the Terms and the intended and normal use of the Purikura, you may
        not copy, reproduce, recompile, decompile, disassemble, distribute, publish, display, perform, modify,
        upload to create derivative works from, transmit, in any case reverse engineer and/or in any other way
        exploit any part of the Purikura and/or its Content.
      </p>
      <p>
        You represent and warrant that you will not make any direct or indirect commercial use of the User
        Content or bring Coty or any of the Related Parties into disrepute.
      </p>
    `,
  },
  {
    title: '6. WARRANTY AND LIMITATION OF LIABILITY',
    // language=HTML
    content: `
      <p>
        The Purikura is provided by Coty on an 'as is' and 'as available' basis. Coty makes no representations
        or warranties of any kind, express or implied, as to the use of the Purikura or to the User Content. You
        expressly agree that your use of the Purikura is at your sole risk.
      </p>
      <p>
        To the full extent permissible by applicable law, Coty disclaims all warranties, express or implied,
        including, but not limited to, implied warranties of merchantability and fitness for a particular
        purpose.
      </p>
      <p>
        Coty will not be liable for any damages of any kind arising from the use of the Purikura, including, but
        not limited to direct, indirect, incidental, punitive, and consequential damages.
      </p>
    `,
  },
  {
    title: '7. INDEMNITY',
    // language=HTML
    content: `
      <p>
        You will indemnify and hold harmless Coty and the Related Parties from and against any and all fines,
        penalties, liabilities, losses and other damages of any kind whatsoever (including attorneys’ and
        experts’ fees), incurred by the Related Parties, and shall defend the Related Parties against any and
        all claims arising out of :
      </p>
      <ol>
        <li>your use of the Purikura,</li>
        <li>your breach of these Terms;</li>
        <li>fraud you commit, or your intentional misconduct or gross negligence</li>
      </ol>
      <p>
        The applicable Related Party will control the defense of any claim to which this indemnity may apply,
        and in any event, you shall not settle any claim without the prior written approval of such Related
        Party.
      </p>
      <p>
        This provision shall be considered without prejudice to the right to compensation and to any damages
        which may be claimed otherwise by the Related Parties in case of a break of these Terms.
      </p>
    `,
  },
  {
    title: '8. ENTIRE AGREEMENT AND GOVERNING LAW',
    // language=HTML
    content: `
      <p>
        These Terms, including the Coty Privacy Policy located at <a href='https://privacy.coty.com/en_gb/'
                                                                     target='_blank'>https://privacy.coty.com/en_gb/</a>
        and any additional terms you may enter into with Coty in connection with the use of the Purikura, shall
        constitute the entire agreement between you and Coty.
      </p>
      <p>
        To the fullest extent permitted by law, any dispute of any sort that might arise between you and Coty
        from the use of the Purikura, shall be governed by French Law. To the fullest extent permitted by law,
        you hereby expressly agree that any proceeding arising out of or relating to your use of the Experience,
        shall be instituted before the Courts of Paris.
      </p>
    `,
  },
  {
    title: '9. CONTACT US',
    // language=HTML
    content: `
      <p>
        If you have any questions or comments regarding these Terms, please contact us via the website below: <a
        href='https://coty.cotyconsumeraffairs.com/' target='_blank'>https://coty.cotyconsumeraffairs.com/</a>
      </p>
    `,
  },
]
